import { React, useState } from 'react';
import ApproveSurveyCertificateComponent from './ApproveSurveyCertificateComponent';
import ApproveSurveyCertificateTable from './ApproveSurveyCertificateTable';
import ENV from "../config";
function ApproveSurveyComponent({ surveyItems, companyName, status, flow }) {

    let surveyclassname = "button-3d";

    switch (true) {
        case status === "New":
            surveyclassname = "button-3dnew";
            break;
        case status === "In-Progress":
            surveyclassname = "button-3dinprogress";
            break;
        case status === "Completed":
            surveyclassname = "button-3dcompleted";
            break;
        case status === "Approved":
            surveyclassname = "button-3dapproved";
            break;
        default:
            surveyclassname = "button-3d";
            break;
    }

    const [expandedIndices, setExpandedIndices] = useState([]); // Track the indices of the expanded childcards

    const toggleSurveyVisibility = (index) => {
        const currentIndex = expandedIndices.indexOf(index);
        if (currentIndex !== -1) {
            // If the clicked childcard is already expanded, close it
            setExpandedIndices(prevIndices => prevIndices.filter(i => i !== index));
        } else {
            // Otherwise, expand the clicked childcard
            setExpandedIndices(prevIndices => [...prevIndices, index]);
        }
    };

    return (
        <div>
            {surveyItems.map((surveyItem, index) => (
                <div className={expandedIndices.includes(index) ? 'newChildCard' : 'childcard'} key={surveyItem.surveyId}>
                    <h4>{surveyItem.surveyName}</h4>
                    {/* <ApproveSurveyCertificateComponent
                        surveyCertificateItems={surveyItem.surveyCertificateList}
                        surveyName={surveyItem.surveyName}
                        surveyId={surveyItem.surveyId}
                        companyName={companyName}
                        vesselId={surveyItem.vesselId}
                        flow={flow}
                        status={status}
                    /> */}
                    <div>
                        <div>
                            <button
                                key="View All Certificates"
                                className={surveyclassname}
                                onClick={() => toggleSurveyVisibility(index)}
                            >
                                {expandedIndices.includes(index) ? "Hide Certificates" : "View Certificates"}
                            </button>
                        </div>
                        {expandedIndices.includes(index) && (
                            <div>
                                < ApproveSurveyCertificateTable data={surveyItem.surveyCertificateList} status={status} surveyName={surveyItem.surveyName} surveyId={surveyItem.surveyId} companyName={companyName} vesselId={surveyItem.vesselId} flow={flow} />
                            </div>
                        )}


                    </div>

                </div>

            ))}

        </div>
    );
}

export default ApproveSurveyComponent;