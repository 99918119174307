import React from 'react';
import SurveyCertificateComponent from './SurveyCertificateComponent';
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import SurveyCertificateTable from './SurveyCertificateTable';
import ENV from "../config";
function SurveyComponent({ surveyItems, companyName, status, flow }) {

    const navigate = useNavigate();
    const [showSurveyCertificates, setShowSurveyCertficates] = useState(false);
    const location = useLocation();
    // console.log(companyName)
    // console.log(flagPath)
    // console.log(status)
    // console.log(flow)

    let surveyclassname = "button-3d";

    switch (true) {
        case status === "New":
            surveyclassname = "button-3dnew";
            break;
        case status === "In-Progress":
            surveyclassname = "button-3dinprogress";
            break;
        case status === "Completed":
            surveyclassname = "button-3dcompleted";
            break;
        case status === "Approved":
            surveyclassname = "button-3dapproved";
            break;
        default:
            surveyclassname = "button-3d";
            break;
    }
    const handleClickSurveyItem = () => {
        if (!showSurveyCertificates) {
            setShowSurveyCertficates(true)

            setTimeout(() => {
                // Code to be executed after the delay
                // ...
            }, 700);

        }

        else
            setShowSurveyCertficates(false)

    };

    const [expandedIndices, setExpandedIndices] = useState([]); // Track the indices of the expanded childcards
    const toggleSurveyVisibility = (index) => {
        const currentIndex = expandedIndices.indexOf(index);
        if (currentIndex !== -1) {
            // If the clicked childcard is already expanded, close it
            setExpandedIndices(prevIndices => prevIndices.filter(i => i !== index));
        } else {
            // Otherwise, expand the clicked childcard
            setExpandedIndices(prevIndices => [...prevIndices, index]);
        }
    };

    return (
        <div>
            {surveyItems.map((surveyItem, index) => (
                <div className={expandedIndices.includes(index) ? 'newChildCard' : 'childcard'} key={surveyItem.surveyId}>
                    <h4>{surveyItem.surveyName}</h4>
                    {/* <SurveyCertificateComponent  
                    surveyCertificateItems = {surveyItem.surveyCertificateList}
                    surveyName={surveyItem.surveyName}
                    surveyId={surveyItem.surveyId}
                    companyName={companyName}
                    flow={flow}
                    flagPath={surveyItem.flagPath}
                    status={status}
                    /> */}
                    <div>
                        <div>
                            <button
                                key="View All Certificates"
                                className={surveyclassname}
                                onClick={() => toggleSurveyVisibility(index)}

                            >
                                {expandedIndices.includes(index) ? "Hide Certificates" : "View Certificates"}


                            </button>

                        </div>

                        {expandedIndices.includes(index) && (
                            <div>
                                <SurveyCertificateTable
                                    data={surveyItem.surveyCertificateList}
                                    status={status}
                                    surveyName={surveyItem.surveyName}
                                    surveyId={surveyItem.surveyId}
                                    companyName={companyName}
                                    flow={flow}
                                    flagPath={surveyItem.flagPath}
                                />
                            </div>
                        )}

                    </div>
                </div>

            ))}

        </div>
    );
}

export default SurveyComponent;