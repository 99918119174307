import './Css/App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";


import Register from './Register';
import Signin from './Signin';
import Home from './Pages/Home';
import LinkPage from './Pages/LinkPage';
import Unauthorized from './Pages/Unauthorized';
import Auth from './Pages/Auth';
import CertEditor from './Pages/Admin/CertEditor';
import TemplateChooser from './Pages/Admin/TemplateChooser'
import TemplateList from './Pages/Admin/TemplateList';
import PdfPreview from './Pages/Admin/PdfPreview';
import FinalizedPdfPreview from './Pages/FinalizedPdfPreview';
import SavedPdfPreview from './Pages/SavedPdfPreview';
import ApprovedFinalizedPdfPreview from './Pages/ApprovedFinalizedPdfPreview';
import ErrorPage from './Pages/ErrorPage';
import QRCodeGenerator from './Pages/QRCodeGenerator';
import CreateSurvey from './Pages/CreateSurvey';
import SurveyDashboard from './Pages/Dashboards/SurveyDashboard';
import SurveyorDashboard from './Pages/Dashboards/SurveyorDashboard';
import ApproverDashboard from './Pages/Dashboards/ApproverDashboard';
import CertEditorSurveyor from './Pages/CertEditorSurveyor';
import AddCompanyDetails from './Pages/AddCompanyDetails';
import { AuthProvider } from './Context/authProvider';
import { useEffect } from 'react';
import LandingPage from './Pages/LandingPage/LandingPage';
import CertEditorNew from './Pages/Admin/CertEditorNew';

const superadmin = ['Super Admin'];
const admin = ['Super Admin', 'Admin'];
const projectmanager = ['Super Admin', 'Admin', 'Project Manager'];
const surveyor = ['Super Admin', 'Admin', 'Project Manager', 'Surveyor'];
const user = ['Super Admin', 'Admin', 'Project Manager', 'Surveyor', 'User'];
const userRole = localStorage.getItem('role')


function App() {

  return (
    <BrowserRouter>
      <div>
        <main>
          <div>
            <Routes>

              <Route path="/" element={<LandingPage />} />
              <Route path='home' element={<Home />} />
              <Route path="/choose-template" element={(admin.includes(userRole)) ? <TemplateChooser /> : <Unauthorized />} />
              <Route path='/list-templates' element={(admin.includes(userRole)) ? <TemplateList /> : <Unauthorized />} />
              <Route path='/survey-dashboard' element={(projectmanager.includes(userRole)) ?<SurveyDashboard />:<Unauthorized/>} />
              <Route path='/surveyor-dashboard' element={(surveyor.includes(userRole)) ?<SurveyorDashboard />:<Unauthorized/>} />
              <Route path='/approver-dashboard' element={(projectmanager.includes(userRole)) ?<ApproverDashboard />:<Unauthorized/>} />
              <Route path='/add-company-details' element={(projectmanager.includes(userRole)) ?<AddCompanyDetails />:<Unauthorized/>} />
              <Route path='/create-survey' element={(projectmanager.includes(userRole))?<CreateSurvey />:<Unauthorized/>} />
              <Route path='/signin' element={<Signin />} />
              <Route path='/approved-finalized-pdf-previewer' element={(projectmanager.includes(userRole)) ? <ApprovedFinalizedPdfPreview /> : <Unauthorized />} />
              <Route path='/saved-pdf-previewer' element={(surveyor.includes(userRole)) ?<SavedPdfPreview /> : <Unauthorized/>} />
              <Route path='/finalized-pdf-previewer' element={(surveyor.includes(userRole))? <FinalizedPdfPreview /> : <Unauthorized/>} />
              <Route path='/pdf-previewer' element={(admin.includes(userRole)) ? <PdfPreview />: <Unauthorized />} />
              <Route path='/qrcode-generator' element={<QRCodeGenerator />} />
              <Route path='/cert-editor-surveyor' element={(surveyor.includes(userRole))? <CertEditorSurveyor />: <Unauthorized/>} />
              <Route path='/error-page' element={<ErrorPage />} />
              <Route path='/register' element={(admin.includes(userRole)) ?<Register /> : <Unauthorized/>} />
              <Route path='/cert-editor' element={(admin.includes(userRole)) ? <CertEditor /> : <Unauthorized/>} />
              <Route path='/cert-editorNew' element={(admin.includes(userRole)) ? <CertEditorNew /> : <Unauthorized/>} />


            </Routes>
          </div>
        </main>
        <footer></footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
