import * as React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import ENV from "../config";
import Modal from 'react-modal';
import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import '../Css/customckeditor.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import CircularLoader from '../Components/CircularLoader';
import handleToken from '../Components/TokenHandler';


const { version, useReducer } = React;
/* Liz Added */
const { useEffect, useState } = React;


//const USER_SERVICE_URL = 'http://44.207.146.240/api/surveyor/html-string';
const GET_HTML_SERVICE_URL = ENV.backend + '/api/surveyor/html-string';


const SAVE_SERVICE_URL = ENV.backend + '/api/surveyor/html-to-pdf-save';

const COMPLETION_SERVICE_URL = ENV.backend + '/api/surveyor/html-to-pdf-finalize';


const styles = "<style> @page {size: A4;margin: 0.2cm 1cm 0.5cm 1cm;@bottom-center {content:'Page ' counter(page) ' of ' counter(pages);font-size: 8pt; margin-top:0;}}.todo-list__label__description {position: relative;top: -2px;} ::marker{font-size: xx-small;} body p span {margin-bottom: 0px !important;margin-top: 0px !important; line-height: 1;}body p {margin-bottom: 0px !important;margin-top: 0px !important;line-height: 1;} .todo-list {list-style: none; margin:0;}.todo-list .todo-list__label > input {margin-top:8px;}.table .ck-table-resized { table-layout: fixed; } .table table { overflow: hidden; } .table td, .table th { overflow-wrap: break-word; position: relative; padding: 0 !important;} .table > figcaption { display: table-caption; caption-side: top; word-break: break-word; text-align: center; padding: .6em; font-size: .75em; outline-offset: -1px; } .table { margin: 0 auto; display: table; } .table table { border-collapse: collapse; border-spacing: 0; width: 100%; height: 100%; } .table table td, .table table th { min-width: 2em; padding: 0 !important; } .table table th { font-weight: bold; } .table th { text-align: right; } .table th { text-align: left; }.table table tr {page-break-inside: avoid;  page-break-before: auto; page-break-after: auto;}.table tr {page-break-inside: avoid;  page-break-before: auto; page-break-after: auto;}</style>"
//const top = "<?xml version=\"1.0\" encoding=\"utf-8\"?><!DOCTYPE html PUBLIC \"-//W3C//DTD XHTML 1.1//EN\" \"http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd\"><html xmlns=\"http://www.w3.org/1999/xhtml\"><head><meta http-equiv=\"Content-Type\" content=\"application/xhtml+xml; charset=utf-8\" /><title>THE GOVERNMENT OF BELIZE</title><link href=\"UserDefinedFileName.css\" type=\"text/css\" rel=\"stylesheet\"/></head><body>";
const top = '<?xml version="1.0" encoding="utf-8"?><!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><meta http-equiv="Content-Type" content="application/xhtml+xml; charset=utf-8" /><title>NEOCAP TEMPLATES</title></head>'
const body = "<body>"
const bottom = "</body></html>"

/**
 * `App` component manages state of underlying `CKEditor` and `Sidebar` components.
 *
 * Custom `CKEditor` component memoizes certain props and it will ignore any new values. For instance, this is true for `config` and `type`.
 * In order to force new `config` or `type` values, use keyed component.
 * This way `CKEditor` component is re-mounted and new instance of editor is created.
 */
function CertEditorSurveyor() {

	const { auth } = useContext(AuthContext);
	const [userdata, setUserData] = useState({ users: "", isFetching: false });
	const [editor1, setEditor] = useState();
	const location = useLocation();
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(false);
	const [showSurveyCertificates, setShowSurveyCertficates] = useState(false);
	const surveycertificatename = location.state.surveycertificatename ?? '';
	const company = location.state.company ?? '';
	const surveyname = location.state.surveyname ?? '';
	const surveyid = location.state.surveyid ?? '';
	const surveycertificateid = location.state.surveycertificateid ?? '';
	const flagpath = location.state.flagpath ?? '';
	const status = location.state.status ?? '';


	const [issuedDate, setIssuedDate] = useState('');
	const [expiryDate, setExpiryDate] = useState('');
	const [certificateGoodName, setCertificateGoodName] = useState('');
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [showEditor, setShowEditor] = useState(true);
	const [showComplete, setShowComplete] = useState(false);
	const [issuedPlace, setIssuedPlace] = useState('');
	const [certificateType, setCertificateType] = useState('');
	//const surveycertificatename = location.state.surveycertificatename ?? '';


	const [showMessage, setShowMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const totemplatelist = location.state?.from?.pathname || "/surveyor-dashboard"
	const topdfpreviewer = "/saved-pdf-previewer";
	useEffect(() => {
		handleToken();
	})
	/** Liz Added below */
	useEffect(() => {
		setShowMessage(false);


		//console.log(editor)
		//if ( editor && status === 'ready' ) {
		console.log("inside")
		const fetchUsers = async () => {
			try {
				console.log("inside sync")
				setUserData((userdata) => ({ users: userdata.users, isFetching: false }));
				console.log(flagpath)

				const response = await axios.get(GET_HTML_SERVICE_URL, {
					params: {
						templateName: surveycertificatename,
						companyName: company,
						surveyName: surveyname,
						surveyId: surveyid,
						flagPath: flagpath

					}
				})

				console.log("inside sync" + response.data);


				//const base64 = require('base64topdf')
				//const decodedresponse = base64.decode(response.data)
				setUserData({ users: response.data, isFetching: false });
				//console.log(decodedresponse);
				console.log('after set');
				//console.log(editor.getData());
				//editor.setData(response.data)

			} catch (e) {
				console.log(" errored")
				console.log(e);
				setUserData((userdata) => ({ users: userdata.users, isFetching: false }));
			}
		};
		fetchUsers();
	}, []);


	const handlePreviewPDF = () => {

		handleSave();
		setTimeout(() => {
			proceedWithPreview();
		}, 2000); // 1000 milliseconds = 1 second

	};

	const proceedWithPreview = () => {
		let certificateName = location.state.surveycertificatename;

		if (certificateName && certificateName !== null) {
			certificateName = certificateName.replace(".html", ".pdf");
		}

		console.log("calling");

		try {
			navigate(topdfpreviewer, {
				state: {
					company: location.state.company,
					surveyid: location.state.surveyid,
					surveyname: location.state.surveyname,
					surveycertificateid: location.state.surveycertificateid,
					surveycertificatename: certificateName,
					flow: ""
				},
			});
		} catch (e) {
			console.log("errored");
			console.log(e);
		}
	};

	const handleBack = () => {
		navigate(0);
	};

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};


	//   const handleClickSurveyItem = () => {
	// 	if (!showSurveyCertificates){
	// 	setShowSurveyCertficates(true)

	// 	setTimeout(() => {
	// 	  // Code to be executed after the delay
	// 	}, 700);

	// 	}

	// 	else
	// 	setShowSurveyCertficates(false)

	// 	};

	const handleClose = evt => {
		console.log("naviate to totemplatelist:", totemplatelist)
		navigate(totemplatelist);
	}
	const handleSave = evt => {
		setLoading(true);
		console.log("Saving data");
		let tagRemovedData = removeTagsAndAddSpace(editor1.getData());
		const value1 = ((top + styles + body + tagRemovedData + bottom));
		const value = ((value1));
		console.log("Hmtl sending", value);
		console.log(surveycertificatename);
		console.log(company);
		const actionSubmittedOnString = (new Date());
		let actionSubmittedBy = "";
		if (localStorage.getItem("userFullName") !== "undefined") {
			actionSubmittedBy = localStorage.getItem("userFullName");
		} else if (localStorage.getItem("userId")) {
			actionSubmittedBy = localStorage.getItem("userId");
		} else {
			actionSubmittedBy = "Unknown";
		}
		const data = {
			companyName: company,
			templateName: surveycertificatename,
			htmlString: value,
			surveyCertificateName: surveycertificatename,
			surveyCertificateId: surveycertificateid,
			surveyName: surveyname,
			surveyId: surveyid,
			actionSubmittedBy: actionSubmittedBy,
			actionSubmittedOn: new Date()
		};

		axios.post(SAVE_SERVICE_URL, data, {
			headers: {
				// 'Content-Type': 'text/html',
			}
		})
			.then(response => {
				// Save operation successful
				console.log("Save successful:", response);

				// Display message after 3 seconds
				setShowMessage(true);
				setLoading(false);
				setTimeout(() => {
					setShowMessage(false);
				}, 7000);

				// Close the template list after saving

				if (status == "New") {
					handleClose();


				}

			})
			.catch(error => {
				// Handle error if save operation fails
				console.error("Error saving data:", error);

				// Display error message
				setErrorMessage("Error saving data. Please try again.");
				setTimeout(() => {
					setErrorMessage("");
				}, 5000);
			});
	};

	const handleChooseDate = evt => {

		handleSave()

		openModal();
		setUserData({ users: editor1.getData(), isFetching: false });
		setShowEditor(false)

	};


	const handleComplete = evt => {
		setLoading(true);
		let tagRemovedData = removeTagsAndAddSpace(editor1.getData());
		const value1 = ((top + styles + body + tagRemovedData + bottom));
		const value = ((value1));
		const formatDateToString = (date) => {
			console.log("dated", date);

			// Check if the input date is valid
			if (isNaN(date.getTime())) {
				// If the date is invalid, return an empty string
				return "";
			}

			// Format the valid date
			const yyyy = date.getFullYear();
			const mm = date.toLocaleString('default', { month: 'short' }).toUpperCase();
			const dd = String(date.getDate()).padStart(2, '0');
			const finalDate = `${dd}-${mm}-${yyyy}`;
			if (finalDate === "01-JAN-1970") {
				return "";
			}
			else {
				return finalDate;
			}
		};


		const certExpiryDate = new Date(expiryDate);
		const certIssueDate = new Date(issuedDate);

		const certExpiryDateString = formatDateToString(certExpiryDate);
		const certIssueDateString = formatDateToString(certIssueDate);
		const actionSubmittedOnString = (new Date());
		let actionSubmittedBy = "";
		if (localStorage.getItem("userFullName") !== "undefined") {
			actionSubmittedBy = localStorage.getItem("userFullName");
		} else if (localStorage.getItem("userId")) {
			actionSubmittedBy = localStorage.getItem("userId");
		} else {
			actionSubmittedBy = "Unknown";
		}
		// console.log (editor1.getData('docx'));
		//dispatch( { type: 'submit', payload: data } );
		//const response = "";
		//console.log(value)
		console.log(surveycertificatename)
		console.log(company)
		console.log(surveyname)
		console.log(surveyid)
		console.log(surveycertificateid)
		console.log(certificateGoodName)
		//const response =  axios.post(CONVERTER_SERVICE_URL,value);
		const data = {
			companyName: company,
			templateName: surveycertificatename,
			htmlString: value,
			surveyName: surveyname,
			surveyId: surveyid,
			surveyCertificateId: surveycertificateid,
			surveyCertificateName: surveycertificatename,
			certificateGoodName: certificateGoodName,
			certIssueDate: certIssueDateString,
			certExpiryDate: certExpiryDateString,
			issuedAt: issuedPlace,
			certificateType: certificateType,
			actionSubmittedBy: actionSubmittedBy,
			actionSubmittedOn: new Date()
		};



		const response = axios.post(COMPLETION_SERVICE_URL, data, {
			headers: {
				// 'Content-Type': 'text/html',
			}
		});

		response.then(() => {
			setShowEditor(true);
			setShowMessage(true);
			handleClose();
			setLoading(false);
			setTimeout(() => {
				setShowMessage(false);
			}, 7000);
		}).catch(error => {
			console.error('Error:', error);
		});

		console.log("inside sync" + response);


	};

	const handleDateSelection = () => {
		setShowComplete(true);

		setShowEditor(true);
		console.log(certificateGoodName)


		closeModal();
	}


	function removeTagsAndAddSpace(htmlString) {
		// Regular expression to match <o:wrapblock>...</o:wrapblock>
		const wrapblockRegex = /<o:wrapblock>.*?<\/o:wrapblock>/gs;
		// Regular expression to match <o:p></o:p>
		const pTagRegex = /<o:p><\/o:p>/g;

		// Replace <o:wrapblock>...</o:wrapblock> with a space
		let result = htmlString.replace(wrapblockRegex, ' ');
		// Replace <o:p></o:p> with a space
		result = result.replace(pTagRegex, ' ');

		return result;
	}


	return (
		<div>
			{showEditor && (
				<div className='editor-modal' >

					< form className="editor-modal-content animate">


						<div className="paper flex-grow-3">
							<CKEditor
								editor={ClassicEditor}
								id="ckeditor1"
								data={userdata.users}


								config={{
									watchdog: false,
									title: false,
									heading_styles: {
										'font-size': '8px',
									},
									toolbar: {

										shouldNotGroupWhenFull: true
									},
									htmlSupport: {
										allow: [
											{
												name: /.*/,
												attributes: true,
												classes: true,
												styles: false,
												title: false
											}
										],
									},
									fontFamily: {
										options: [
											'default',
											'Times New Roman, Times, serif',
											'Book Antiqua, serif',
											'Arial, Helvetica, sans-serif',
											'Courier New, Courier, monospace',
											'Georgia, serif',
											'Lucida Sans Unicode, Lucida Grande, sans-serif',
											'Tahoma, Geneva, sans-serif',
											'Trebuchet MS, Helvetica, sans-serif',
											'Verdana, Geneva, sans-serif'

										]

									},
									fontSize: {
										options: [
											9,
											'default',
											10,
											11,
											12,
											13,
											14,
											15,
											16,
											17,
											18,
											19,
											20,
											21
										]
									},


								}}
								onInit={(editor) => {
									// You can store the "editor" and use when it is needed.
									// console.log("Editor is ready to use!", editor);

									editor.editing.view.change((writer) => {
										writer.setStyle(

											editor.editing.view.document.getRoot()

										);
									});
								}
								}
								onReady={editor => {
									// You can store the "editor" and use when it is needed.
									setEditor(editor)

									console.log('Editor is ready to use!');
								}}


							/>

						</div>

						{showMessage && <div><b><green>Certificate is created! You can close the editor safely!</green></b></div>}
						{loading ? <CircularLoader /> : (
							<div >
								<div class="btn-container-multiple">
									<button className="modal-close-btn" onClick={handleClose}>Close</button>
									{!showComplete && status !== "New" && (
										<input
											id="submit"
											type="button"
											value="Choose Date to Complete"

											onClick={handleChooseDate}

											className="modal-date-btn"

										//onClick={()=>console.log(document.getElementById('cke_ckeditor').innerHTML)}
										/>
									)}
									{showComplete && !loading &&

										<input
											id="submit"
											type="button"
											value="Complete"

											onClick={handleComplete}

											className="modal-date-btn"

										//onClick={()=>console.log(document.getElementById('cke_ckeditor').innerHTML)}
										/>
									}
									{status !== "New" && (

										<button
											class="modal-preview-btn"
											onClick={handlePreviewPDF}
										>
											<b>Preview PDF</b>
										</button>
									)}
									<button
										class="modal-submit-btn"
										id="submit"
										type="button"
										value="Submit"
										onClick={handleSave}

									><b>{status === "New" ? "Save & Close" : "Save"}</b></button>
								</div>
							</div>)}




					</form>
				</div>)}
			<div  ><Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
				<h2>Select Issued and Expiry Dates</h2>

				<label><b>Issued Date:</b></label>
				<DatePicker

					selected={issuedDate}
					onChange={(date) => setIssuedDate(date)}
					dateFormat="dd-MMM-yyyy"
					required
				/>
				<label> <b>Expiry Date:</b></label>
				<DatePicker

					selected={expiryDate}
					onChange={(date) => setExpiryDate(date)}
					dateFormat="dd-MMM-yyyy"
					filterDate={(date) => date >= issuedDate}
					required
				/>

				<label><b> Issued Place:</b></label>
				<input
					type="text"
					value={issuedPlace}
					onChange={(e) => setIssuedPlace(e.target.value)}
					placeholder="Issued Place"
					required
				/>

				<label><b>Certificate Type:</b></label>
				<select
					className="select-dropdown"
					value={certificateType}
					onChange={(e) => setCertificateType(e.target.value)}
					required
				>
					<option value="" disabled>Select Certificate Type</option>
					<option value="Interim">Interim</option>
					<option value="Full-Term">Full-Term</option>
					<option value="Short-Term">Short-Term</option>

				</select><br></br>



				<label><b> Certificate Name:</b></label>
				<input
					type="text"
					value={certificateGoodName}
					onChange={(e) => setCertificateGoodName(e.target.value)}
					placeholder="Add Certificate Name"
					required
				/>
				<div class="btn-container-multiple">
					<input
						id="back"
						type="button"
						value="Close"
						onClick={handleBack}
						className="modal-close-btn"
					/>

					<input id="submit" type='submit' value="Save"
						onClick={handleDateSelection} className='modal-submit-btn' />
				</div>
			</Modal>
			</div>
			<footer>{"NeoCap Solutions"}</footer>
		</div>
	);
}




export default CertEditorSurveyor;