import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import ApproveSurveyComponent from "../../Components/ApproveSurveyComponent";
import ENV from "../../config";
import Loader from "../../Components/Loader";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LeftNav from "../../Components/LeftNav/LeftNav";
import Header from "../../Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleToken from "../../Components/TokenHandler";
import LoaderDashboard from "../../Components/LoaderDashboard";

function ApproverDashboard() {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const goback = location.state?.from?.pathname || "/";

  useEffect(() => {
    fetchSurveys();
    handleToken();
  }, []);

  const handleClose = () => {
    navigate(goback);
  };

  const fetchSurveys = async () => {
    try {
      const response = await axios.get(ENV.backend + "/api/survey");

      console.log(Object.values(response.data));

      setSurveys(Object.values(response.data));
      // setSurveys(items)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching surveys:", error);
    }
  };

 

  const getLastCreatedDate = (surveys) => {
    const createdDates = surveys.flatMap((survey) =>
      survey.surveyCertificateList.map((item) => new Date(item.createdOn))
    );

    if (createdDates.length === 0) {
      return 0; // Return 0 for surveys without created dates
    }

    const lastCreatedDate = Math.max(...createdDates);

    return lastCreatedDate;
  };
  const surveyMap = new Map();

  surveys.forEach((survey) => {
    const { companyName, ...rest } = survey;
    if (surveyMap.has(companyName)) {
      surveyMap.get(companyName).push(rest);
    } else {
      surveyMap.set(companyName, [rest]);
    }
  });

  // const newItems = updatedChildItems.every(item => item.completed);
  //const inProgressItems = surveys.filter(survey => survey.surveyStatus === 'In-Progress');

  //const completedItems = surveys.filter(survey => survey.surveyStatus === 'Completed');

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "80%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper">
                    <div class="scroll-Dash">
                      <div className="header-container">
                        <h1><div className="header-surveyor">Approver Dashboard</div></h1>
                        {/* <HighlightOffIcon  sx={{  display: "flex",
color:"#e05050",fontSize: "50px"}} onClick={handleClose}>
              
            </HighlightOffIcon> */}
                        {" "}

                      </div>
                      {
                        loading?( <LoaderDashboard />):(
                          <div>
                      {Array.from(surveyMap)
                        .sort(([keyA, valueA], [keyB, valueB]) => {
                          const lastCreatedDateA = getLastCreatedDate(valueA);
                          const lastCreatedDateB = getLastCreatedDate(valueB);

                          return lastCreatedDateB - lastCreatedDateA;
                        })
                        .map(([key, value], index) => {
                          const completedItems = value.filter((survey) =>
                            survey.surveyCertificateList.some(
                              (item) => item.surveyCertificateStatus === "Completed"
                            )
                          );

                          const approvedItems = value.filter((survey) =>
                            survey.surveyCertificateList.some(
                              (item) => item.surveyCertificateStatus === "Approved"
                            )
                          );

                          return (
                            <div key={key}>
                              <Accordion defaultExpanded={index === 0}
                                sx={{
                                  boxShadow: "none",

                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                  key={key}
                                  sx={{
                                    border: "0.1px solid",
                                    borderRadius: "10px",
                                    borderColor: "#bfcddb",
                                    marginBottom: '0.5rem',
                                    // marginTop: '0.5rem',
                                    backgroundColor: "#f4faff",
                                    // margin: "0.5rem"
                                  }}
                                >
                                  <h2 style={{ marginBottom: "0" }}>{key}</h2>
                                </AccordionSummary>
                                <AccordionDetails key={key}
                                  sx={{
                                    borderRadius: "10px",
                                    // backgroundColor: "#f3eeee",
                                    // margin: "0 0.5rem",
                                    marginBottom: "0.5rem",
                                    padding: "0 ",
                                    backgroundColor: "#f4faff"

                                  }}
                                >
                                  {completedItems.length !== 0 && (
                                    <div className="completed">
                                       <label style={{ fontWeight: "bold", color: "brickred" }}>
                                        {" "}
                                         Completed Surveys
                                      </label>
                                      <ApproveSurveyComponent
                                        surveyItems={completedItems}
                                        companyName={key}
                                        status={"Completed"}
                                        flow={"Approval"}
                                      />
                                     
                                    </div>
                                  )}

                                  {approvedItems.length !== 0 && (
                                    <div className="approved">
                                      <label style={{ fontWeight: "bold", color: "brickred" }}>
                                        {" "}
                                         Approved Surveys
                                      </label>
                                      <ApproveSurveyComponent
                                        surveyItems={approvedItems}
                                        companyName={key}
                                        status={"Approved"}
                                        flow="Approval"
                                      />
                                      
                                    </div>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          );
                        })}
                        </div>
                        )}
                    </div>

                    {/* <button className="modal-close-btn" onClick={handleClose}>
        Close
      </button> */}
                  </div>
                </div>

              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default ApproverDashboard;
