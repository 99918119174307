import { useNavigate, useLocation } from "react-router";
import { useState, useContext } from "react";
import { useAuth, AuthProvider } from "./Context/authProvider";
import './Css/pages.css';
import { roleElements } from "aria-query";
import env from "./config";
import Loader from "./Components/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  //const from = location.state?.from?.pathname || "/home"
  const goback = location.state?.from?.pathname || "/";
  // const surveyDashboard ='/survey-dashboard';
  const { auth, setAuth } = useAuth();
  const [name, setName] = useState('');
  const [password, setPassword] = useState('Test@1234');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    try {
      //fetch('http://cdcappidentityapi-test.us-west-2.elasticbeanstalk.com/api/identity/authenticate', {
      fetch(env.identityApi + '/api/identity/authenticate', {
        method: 'POST',
        headers: { 'content-Type': 'application/json' },
        body: JSON.stringify({
          username: name, password: password
        })
      })
        .then(res => res.json())
        .then(res => {
          console.log(res);
          setLoading(false)
          if (!res.accessToken) {
            console.log(res);
            setPasswordError('Incorrect Username or Password!');
            return;
          }
          //            
          localStorage.setItem('accessToken', res.accessToken)
          localStorage.setItem('refreshToken', res.refreshToken)
          const token = parseJwt(res.accessToken);
          localStorage.setItem('userEmail', token.email)
          localStorage.setItem("userId", token.name)
          localStorage.setItem("role", token.role)
          localStorage.setItem("userFullName", res?.userFullName)
          console.log(token)
          const roleName = token.role;
          if (token != null) {
            // setAuth({ role: roleName, userId: token.name, authToken: res.accessToken });
          }
          console.log('role name of the user: ', roleName);
          setName('');
          setPassword('');
          localStorage.setItem('roleName', roleName)
          switch (roleName) {
            case 'Admin':
            case 'SuperAdmin':
              console.log('navigating to admin template section');
              handleClose()
              window.location.replace('/list-templates')
              return;
            case 'Project Manager':
              console.log('navigating to admin template section');
              handleClose()
              window.location.replace('/approver-dashboard')
              return;
            case 'Surveyor':
              console.log('navigating to surveyorDashboard');
              handleClose()
              window.location.replace('/surveyor-dashboard')
              return 'foo';
            default:
              console.log('navigating to surveyorDashboard');
              handleClose()
              window.location.replace('/surveyor-dashboard')
              return 'foo';
          }

        });
    }
    catch (err) {
      setLoading(false)
      if (!err?.response) {
        setError('no server response');
      }
      else {
        setError('registeration failed')
      }
    }
  }

  const handleClose = () => {
    navigate(goback);
  };


  return (
    <>
      {loading && <Loader />}
      <div id='id01' className="modal">

        <form className="modal-content-signin" onSubmit={handleSubmit}>
          <p>{error}</p>



          <div className="modal-new ">
            <h1>Neo CertPro</h1>
            <div>

              <input
                className="rounded-input"
                type='text'
                id='username'
                placeholder="Username"
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="password-input-container">
              <input
                type={showPassword ? 'text' : 'password'}
                id='password'
                className="rounded-input"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                }}
                required

              />
              {password && (
                <div
                  className="toggle-password-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              )}
            </div>

            {passwordError && <h5 className="error-message">{passwordError}</h5>}

            <button className="modal-submit-btn-login" disabled={!name && !password ? true : false}><b>Log in</b></button>
          </div>
        </form>
      </div>

    </>
  );
}

export default Signin;