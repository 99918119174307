import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import Select from "react-select";
import getVessels from "./Vessels";
import getCountry from "./Country";
import getCompany from "./Company";
import ENV from "../config";
import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import Loader from "../Components/Loader";
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import handleToken from "../Components/TokenHandler";



function TemplateNamesSection({ templateNames, selectedTemplateNames, handleLeftTemplateNamesChange, handleRightTemplateNamesChange }) {
  return (
    <div>
      <label className="label" htmlFor="templateNames">Templates</label>
      <div className="template-names-container">
        <div className="template-names-column left-column">
          <div className="temp">Available Templates</div>
          {templateNames
            .sort((a, b) => (a.templateName && b.templateName) ? a.templateName.localeCompare(b.templateName) : 0)
            .map((templateName, index) => (
              <div
                key={templateName.templateId}
                className="template-name-item"
                onClick={() => handleLeftTemplateNamesChange(templateName)}
              >
                {templateName.templateName}
              </div>
            ))}
        </div>
        <SwapHorizontalCircleIcon />
        <div className="template-names-column right-column">
          <div className="temp">Selected Templates</div>
          {selectedTemplateNames && selectedTemplateNames.slice().reverse().map((selectedName, index) => (
            <div
              key={index}
              className="selected-name-item"
              onClick={() => {
                handleRightTemplateNamesChange(selectedName);
                console.log(selectedName.templateName);
              }}
            >
              {selectedName.templateName}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export { TemplateNamesSection };


function CreateSurvey() {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [templateNames, setTemplateNames] = useState([]);
  const [defaultTemplates, setDefaultTemplates] = useState([])
  const [nonHiddenTemplates, setNonHiddenTemplates] = useState([]);
  const [surveyorNames, setSurveyorNames] = useState([]);
  const [selectedTemplateNames, setSelectedTemplateNames] = useState([]);
  const [selectedSurveyTemplateNames, setSelectedSurveyTemplateNames] = useState([]);


  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);


  const [showAddCountry, setShowAddCountry] = useState(false);
  const [hideAddCountry, setHideAddCountry] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [newCountry, setNewCountry] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(true);
  const [selectedSurvey, setSelectedSurvey] = useState('');
  const [newSelectedSurvey, setNewSelectedSurvey] = useState('');

  const [selectedCompanyName, setSelectedCompanyName] = useState("");


  const [selectedSurveyorNames, setSelectedSurveyorNames] = useState([]);

  const goback = location.state?.from?.pathname || "/";
  const surveyordb = location.state?.from?.pathname || "/survey-dashboard";

  const company = companies.map((company) => ({
    value: company,
    label: company.companyName,
  }));

  const country = countries.map((country) => ({
    value: country,
    label: country.countryName,
  }));

  const { auth } = useContext(AuthContext);

  const [selectOption, setSelectOption] = useState([]);

  const [surveyCompany, setSurveyCompany] = useState("DAKAR");
  const [vessel, setVessel] = useState([]);
  const [surveySelected, setSurveySelected] = useState([]);

  const [selectedVessel, setSelectedVessel] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [isClonedSurvey, setIsClonedSurvey] = useState(false);

  const [hideTemplateOptions, setHideTemplateOptions] = useState(false);


  //const [selectedCoutry, setSelectedCountry] = useState("");

  useEffect(() => {
    fetchOptions();
    fetchCompanies();
    fetchCountries();
    fetchSurveys();
    handleToken();
  }, []);

  const fetchSurveys = async () => {
    try {
      const response = await axios.get(ENV.backend + "/api/survey");
      const surveysData = Object.values(response.data);
      console.log("Fetched Surveys:", surveysData);
      setSurveys(surveysData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setLoading(false);
      alert("Error fetching surveys");
    }
  };

  const fetchOptions = async () => {
    try {
      const templateNamesResponse = await axios.get(
        ENV.backend + "/api/template"
      );
      console.log("bearer " + localStorage.getItem('accessToken'));
      const surveyorNamesResponse = await axios.get(
        ENV.identityApi + "/api/identity/GetUsersByRole?role=surveyor",
        { headers: { Authorization: "bearer " + localStorage.getItem('accessToken') } }
      ).catch(e => {
        alert('Error! Failed to obtain surveyor data. Please logout and try again.')
      });

      const templates = Object.values(templateNamesResponse.data);

      const surveyors = Object.values(surveyorNamesResponse.data);

      console.log("templatecheck", templateNamesResponse);
      templates.map((templateName, index) =>
        console.log(templateName.templateId)
      );

      surveyors.map((surveyorName, index) => console.log(surveyorName.id));

      setTemplateNames(templates);
      setDefaultTemplates(templates);
      console.log("templates", templates);
      setSurveyorNames(surveyors);

      //setSurveyorNames(surveyorNamesResponse.data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };



  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
    /*if (event.target.files[0]) {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
    

      fetch(ENV.backend+'/api/upload', {
        method: 'POST',
        body: formData
      })
        .then(response => {
          if (response.ok) {
            console.log('File uploaded successfully');
          } else {
            console.error('Failed to upload file');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }*/
  };



  const fetchCompanies = async () => {
    try {
      const response = await getCompany("");
      console.log(response);
      setCompanies(response);

      setIsLoading(false);
    } catch (error) {
      // Handle error
      console.log("Error:", error);
      setIsLoading(false);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountry("");
      console.log(response);
      setCountries(response);
    } catch (error) {
      // Handle error
      console.log("Error:", error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleValueChange = (value) => {
    console.log(value);
    setSelectedVessel(value);
    setSelectedSurvey('');


    const s = surveys
      .filter((survey) => selectedCompany && survey.companyName === selectedCompany.value.companyName)
      .filter((survey) => survey.vesselId === value.value.id)
      .map((survey) => ({
        value: survey,
        label: survey.surveyName,
      }));

    setSurveySelected(s);
  };
  const handleSurveySelection = (event) => {
    console.log('event passing is .', event)
    const selectedSurveyId = event.value.surveyId;
    console.log('Selected Survey ID:', selectedSurveyId);
    console.log("selectedSurvey is", selectedSurvey);
    console.log("Default templates", defaultTemplates);
    console.log("left template names", templateNames);

    if (selectedSurvey?.value?.surveyId === selectedSurveyId) {
      setSelectedSurvey('');
      setSelectedTemplateNames([]);
      setNonHiddenTemplates(defaultTemplates);
    }
    else {
      const optionSelectedSurvey = surveys.find((survey) => survey.surveyId === selectedSurveyId);
      setSelectedSurvey(event);
      setNonHiddenTemplates([]);
      console.log('Selected Survey:', optionSelectedSurvey);

      if (optionSelectedSurvey) {
        const approvedCertificates = optionSelectedSurvey.surveyCertificateList?.find((cert) => cert.surveyCertificateStatus === "Approved");
        const newlySelectedTemplateNames = approvedCertificates?.map(
          (certificate) => ({ templateName: certificate.certificateName, templateId: certificate.templateId })
        );

        const updatedLeftTemplates = templateNames.filter((template) => {
          return !newlySelectedTemplateNames.some(
            (selectedTemplate) => selectedTemplate.templateName === template.templateName
          );
        });

        setTemplateNames(updatedLeftTemplates);



        console.log('Selected Template Names:', newlySelectedTemplateNames);


        setSelectedTemplateNames(newlySelectedTemplateNames);
        setIsClonedSurvey(true);


      }
      const hideTemplateOptions = optionSelectedSurvey ? true : false;
      setHideTemplateOptions(hideTemplateOptions);
    }
  };


  const handleCompanySelect = async (value) => {
    console.log(value.value.companyId);
    setSelectOption(value);
    setSelectedSurvey('');
    setSurveyCompany("DAKAR");
    setSelectedVessel('');

    console.log(surveyCompany);
    setSelectedCompany(value);
    setSelectedCompanyName(value.value.companyName);

    try {
      const response = await getVessels("", value.value);
      console.log(response);

      const v = response
        .filter((v) => v.companyId === value.value.companyId)
        .map((v) => ({
          value: v,
          label: v.vesselName,
        }));
      console.log("my new vessel list", v)
      setVessel(v)

      const s = surveys
        .filter((survey) => survey.companyName === value.value.companyName)
        .filter((survey) => selectedVessel && survey.vesselId === selectedVessel.value.id)
        .map((survey) => ({
          value: survey,
          label: survey.surveyName,
        }));
      console.log("my new survey list", s)
      setSurveySelected(s);


      setShowAddCountry(true);





    } catch (error) {
      // Handle error
      console.log("Error:", error);
    }
  };

  const handleLeftTemplateNamesChange = (templateName) => {
    if (!selectedTemplateNames.includes(templateName)) {
      setSelectedTemplateNames([...selectedTemplateNames, templateName]);


      const updatedLeftTemplates = templateNames.filter(
        (name) => name !== templateName
      );
      setTemplateNames(updatedLeftTemplates);

      const updatedLeftHiddenTemplates = nonHiddenTemplates.filter(
        (name) => name !== templateName
      );
      setNonHiddenTemplates(updatedLeftHiddenTemplates);
    }
    // setHideTemplateOptions(false);
  };

  const handleRightTemplateNamesChange = (templateName) => {
    const updatedSelectedTemplates = selectedTemplateNames.filter(
      (name) => name !== templateName
    );

    setSelectedTemplateNames(updatedSelectedTemplates);
    console.log("hidden cindition", hideTemplateOptions);
    if (hideTemplateOptions) {
      setNonHiddenTemplates([...nonHiddenTemplates, templateName])
      console.log("ahvhvas", nonHiddenTemplates);
    } else {
      setTemplateNames([...templateNames, templateName]);

    }


  };

  const handleLeftSurveyorNamesChange = (surveyor) => {
    if (!selectedSurveyorNames.some(item => item.userName === surveyor.userName)) {
      setSelectedSurveyorNames([...selectedSurveyorNames, surveyor]);

      const updatedLeftSurveyors = surveyorNames.filter(
        (name) => name.userName !== surveyor.userName
      );
      setSurveyorNames(updatedLeftSurveyors);
    }

  };

  const handleRightSurveyorNamesChange = (surveyor) => {
    const updatedSelectedSurveyors = selectedSurveyorNames.filter(
      (name) => name.userName !== surveyor.userName
    );

    setSelectedSurveyorNames(updatedSelectedSurveyors);
    console.log("hidden cindition", hideTemplateOptions);
    setSurveyorNames([...surveyorNames, surveyor]);

  };

  const handleSurveyorNamesChange = (event) => {
    const clickedOption = event.target.value;
    const isSelected = selectedSurveyorNames.includes(clickedOption);

    if (isSelected) {
      // If the option is already selected, remove it
      const updatedSelection = selectedSurveyorNames.filter(option => option !== clickedOption);
      setSelectedSurveyorNames(updatedSelection);
    } else {
      // If the option is not selected, append it
      setSelectedSurveyorNames(prevSelection => [...prevSelection, clickedOption]);
    }
  };




  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(false);
    // Do something with the submitted data, such as sending it to the server
    console.log("Submitted Name:", name);

    console.log("Selected Template Names:", selectedTemplateNames);
    console.log("Selected Surveyor Names:", selectedSurveyorNames);

    console.log(selectedCountry);
    console.log(selectedVessel);
    const vesselId = parseInt(selectedVessel.value.id);

    console.log("vessel id ", selectedVessel);

    const selectedTemplateNamesArray = selectedTemplateNames
      .filter((value) => value.templateName !== null)
      .map((value) => ({
        templateId: parseInt(value.templateId),
        certificateName: value.templateName,
        surveyCertificateStatus: "New",
        createdOn: new Date(),

        createdBy: localStorage.getItem('userEmail'),

      }));

    const selectedSurveyorNamesArray = selectedSurveyorNames.map((value) => ({
      userId: value.userName,
      createdOn: new Date(),
      createdBy: localStorage.getItem('userEmail'),

    }));

    console.log(selectedTemplateNamesArray);
    console.log(selectedSurveyorNamesArray);

    const newSurvey = {
      surveyName: name,
      surveyCreatedBy: localStorage.getItem('userEmail'),
      companyId: selectOption.value.companyId,
      companyName: selectOption.value.companyName,
      surveyorCompanyName: surveyCompany,
      vesselId: vesselId,
      countryId: selectedCountry.value.countryId,
      countryName: selectedCountry.value.countryName,
      surveyCertificateList: selectedTemplateNamesArray,
      surveyorsList: selectedSurveyorNamesArray,
      surveyStatus: "New",
      surveyCreatedOn: new Date(),
      surveyEndDate: new Date(),
      createdBy: localStorage.getItem('userEmail'),
      createdOn: new Date(),
      isCloned: isClonedSurvey,
      clonedSurveyId: selectedSurvey.surveyId,
      clonedSurveyName: selectedSurvey.surveyName

    };
    console.log("new survey ", newSurvey);
    console.log("selected survey", selectedSurvey.surveyName);

    setIsLoading(true)
    try {
      // Make an API call to the backend to create a new user
      const response = await axios.post(ENV.backend + "/api/survey", newSurvey);

      if (response.status === 201) {
        setIsLoading(false)
        // User created successfully
        console.log("Survey created!");
        navigate(surveyordb);
        // Additional actions if needed
      } else {
        // Error occurred while creating the user
        console.error("Error creating Survey");
        // Additional error handling if needed
      }
    } catch (error) {
      setIsLoading(false)
      console.error("Error:", error);
      // Additional error handling if needed
    }

    // Reset input fields and selections
    setName("");
    setSelectedTemplateNames([]);
    setSelectedSurveyorNames([]);
    handleClose();
  };

  const handleCountrySelect = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleAddCountry = () => {
    setShowAddCountry(true);
    setHideAddCountry(true);
  };

  const handleClose = () => {
    navigate('/survey-dashboard');
  };

  const closeAddCountry = () => {

    setShowAddCountry(true);
    setHideAddCountry(false);

  }



  const handleAddCountrySubmit = async () => {
    console.log(selectOption);
    const newCountryObject = {
      companyId: selectOption.value.companyId,
      countryName: newCountry,

    };

    console.log(newCountryObject);

    try {
      // Make an API call to the backend to create a new country
      const response = await axios.post(
        ENV.backend + "/api/country",
        newCountryObject
      );

      if (response.status === 201) {
        // User created successfully
        console.log("Country created!");
        // Additional actions if needed
      } else {
        // Error occurred while creating the user
        console.error("Error creating Country");
        // Additional error handling if needed
      }
    } catch (error) {
      console.error("Error:", error);
      // Additional error handling if needed
    }

    // Add the new company to the state
    //setCountry([...country, newCountryObject]);

    fetchCountries();
    setNewCountry("");
    setShowAddCountry(false);
    setHideAddCountry(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      <div id="id01" className="modal">
        <form className="modal-content animate" onSubmit={handleSubmit}>
          <h3>Create Survey</h3>
          <div>
            <label className="label" htmlFor="name"><b>Survey Name</b></label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={handleNameChange}
              required
            />
          </div>
          <label className="label" htmlFor="roles"><b>Client Name</b></label>

          <Select
            options={company}
            defaultValue={selectOption}
            onChange={handleCompanySelect} //{setSelectedOption}
            required
          />

          <div>
            <label className="label" htmlFor="vessel">
              <b>Vessel Name</b></label>
            <Select
              options={vessel}
              value={selectedVessel}
              selectOption={selectedVessel}
              onChange={handleValueChange}
            />

          </div>
          <div>
            <label className="label" htmlFor="existingSurveys">
              <b>Existing Surveys</b>
            </label>
            <Select
              options={surveySelected}
              value={selectedSurvey}
              //  selectOption={selectedSurvey}
              onChange={handleSurveySelection} />


          </div>

          <div>

            <label className="label" htmlFor="roles"><b> Flag Country</b></label>

            <div className="select-container">
              <div className="custom-select">
                <Select
                  options={country}
                  value={selectedCountry}
                  selectOption={selectedCountry}
                  onChange={handleCountrySelect} //{setSelectedOption}
                  required
                />
              </div>
              {showAddCountry && (
                <div className="button-container">
                  <AddBoxIcon sx={{ color: "green", width: '40px', height: '40px', marginTop: "-1px", marginRight: "-5px" }} onClick={handleAddCountry}>
                  </AddBoxIcon>
                </div>
              )}

            </div>

            {hideAddCountry && (
              <div id="id02" className="submodal">
                <div className="modal-content animate">
                  <div className="submodal-container">
                    {/* <button className="cross-button" onClick={closeAddCountry}>X</button> */}
                    <input
                      type="text"
                      value={newCountry}
                      onChange={(e) => setNewCountry(e.target.value)}
                      placeholder="Country Name"
                    />

                    <div>

                      <input class="modal-choose-btn" type="file" onChange={handleFileUpload} >
                      </input>
                    </div>
                    <div class="btn-container-multiple">
                      <button class="modal-close-btn" onClick={closeAddCountry}>Close</button>
                      <button class="modal-submit-btn" onClick={handleAddCountrySubmit}>Save</button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div key={selectedTemplateNames.join(',')}>
            <TemplateNamesSection
              templateNames={hideTemplateOptions ? nonHiddenTemplates : templateNames}
              selectedTemplateNames={selectedTemplateNames}
              handleLeftTemplateNamesChange={handleLeftTemplateNamesChange}
              handleRightTemplateNamesChange={handleRightTemplateNamesChange}
            />

          </div>
          <div>
            <div>
              <label className="label" htmlFor="templateNames">Surveyors</label>
              <div className="template-names-container">
                <div className="template-names-column left-column">
                  <div className="temp">Available Surveyors</div>
                  {surveyorNames
                    .map((surveyorName, index) => (
                      <div
                        key={surveyorName.id}
                        className="template-name-item"
                        onClick={() => handleLeftSurveyorNamesChange(surveyorName)}
                      >
                        {surveyorName.firstName + ' ' + surveyorName.lastName}
                      </div>
                    ))}
                </div>

                <div className="template-names-column right-column">
                  <div className="temp">Selected Surveyors</div>
                  {selectedSurveyorNames && selectedSurveyorNames.slice().reverse().map((surveyorName, index) => (
                    <div
                      key={index}
                      className="selected-name-item"
                      onClick={() => {
                        handleRightSurveyorNamesChange(surveyorName);
                        console.log(surveyorName.surveyorName);
                      }}
                    >
                      {surveyorName.firstName + ' ' + surveyorName.lastName}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* <label className="label" htmlFor="surveyorNames">Surveyor</label>
          <select
            className="custom-multiselect"
            id="surveyorNames"
            multiple
            value={selectedSurveyorNames}
            onChange={handleSurveyorNamesChange}
            required
          >
            {surveyorNames.map((surveyorName, index) => (
              <option key={surveyorName.id} value={surveyorName.userName}>
                {surveyorName.firstName + ' ' + surveyorName.lastName}
              </option>
            ))}
          </select> */}


          </div>
          <div class="btn-container-multiple">
            <button className="modal-close-btn" onClick={handleClose}>
              <b>Close</b>
            </button>
            {submitted && (
              <button className="modal-submit-btn" type="submit">
                <b> Save</b>
              </button>
            )}

          </div>
        </form>
      </div>
    </>
  );
}

export default CreateSurvey;