function isTokenExpired(token) {
    return false;

    //The below code is commented because there is no refresh in token is taking place and signing out in every 20 mins.
    // if (!token) {
    //     // Token not provided
    //     return true;
    // }

    // // Split the token into its three parts: header, payload, signature
    // const parts = token.split('.');
    // if (parts.length !== 3) {
    //     // Invalid token format
    //     return true;
    // }

    // // Decode the payload (the middle part)
    // const payload = JSON.parse(atob(parts[1]));
    // // Get the expiration time from the payload
    // const expirationTime = payload?.exp * 1000; // Convert seconds to milliseconds
    // // Compare with the current time
    // const date = new Date(expirationTime);

    // // Convert the date to a human-readable string
    // const formattedDate = date.toLocaleString(); // Use appropriate options for formatting
    // console.log("Expriry time", formattedDate);


    // // const dummyDate = '2024-02-25T23:59:59Z';

    // const dateDummy = new Date(Date.now());

    // // Convert the date to a human-readable string
    // const formattedDummyDate = dateDummy.toLocaleString();
    // console.log("Dummmy date", formattedDummyDate);

    // return formattedDummyDate >= formattedDate;
}

const handleToken = () => {
    const token = localStorage.getItem('accessToken');
    const expiry = isTokenExpired(token);
    console.log("Is token expired", expiry); // Should print false if the token is not expired
    if (expiry) {
        alert('Session Timed Out. Your session has expired. Please logout and try again.');
        // Function to clear localStorage and redirect after 5 seconds
        const clearLocalStorageAndRedirect = () => {
            localStorage.clear();
            window.location.replace('/');
        };

        // Clear localStorage and redirect after 5 seconds if the user doesn't click OK
        const timeoutId = setTimeout(clearLocalStorageAndRedirect, 5000);

        // If the user clicks OK, clear the timeout and redirect immediately
        const handleAlertConfirmation = () => {
            clearTimeout(timeoutId);
            clearLocalStorageAndRedirect();
        };

        // Listen for alert confirmation
        window.addEventListener('click', handleAlertConfirmation);
        window.addEventListener('keydown', handleAlertConfirmation);

        // Cleanup function to remove event listeners
        return () => {
            window.removeEventListener('click', handleAlertConfirmation);
            window.removeEventListener('keydown', handleAlertConfirmation);
        };
    }
};

export default handleToken;