const env = {
   // identityApi: "http://cdcappidentityapi-test.us-west-2.elasticbeanstalk.com",
   // identityApi: "http://cdcappidentityapi-test.ap-south-1.elasticbeanstalk.com",
   //  identityApi: "http://identity-cms-blue-stage.ap-southeast-1.elasticbeanstalk.com",
   identityApi: "identityapi",
   backend: "applicationapi"
   //  backend: "http://test.neocertpro.ncs-apps.com",
   // backend: "http://prd.neocertpro.ncs-apps.com"
   // backend: "http://65.0.76.231/"
   
   // backend: "http://localhost:8081"
}

export default env;