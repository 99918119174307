import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import ENV from "../../config";
import CertEditor from './CertEditor';
import Button from '@mui/material/Button';
import handleToken from '../../Components/TokenHandler';

//const PdfPreview = ({ filename, companyname }) => {
const PdfPreview = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const toeditor = location.state?.from?.pathname || "/cert-editor";
  const filename = location.state.filename || "/cert-editor";
  const companyname = location.state.company || "/cert-editor";
  const totemplatelist = location.state?.from?.pathname || "/list-templates"
  const [companyNames, setCompanyName] = useState(null)
  const [templateNames, setTemplateName] = useState(null)
  const flow = location.state?.flow

  useEffect(() => {
    handleToken();
  })

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        if (!filename || !companyname) {

          navigate(totemplatelist);
          return;
        }
        setTemplateName(filename)
        setCompanyName(companyname)
        console.log(filename, companyname);
        // const response = await fetch(`http://localhost:8081/api/pdfpreview/${filename}/${companyname}`);
        const response = await axios.get(ENV.backend + '/api/pdfpreview', {
          //const response = await axios.get('http://44.207.146.240/api/pdfpreview', {
          params: {
            templateName: filename,
            companyName: companyname
          },
          responseType: 'blob',
        }

        );

        if (response.status === 200) {
          console.log("status ok")
          console.log(response)
          const blob = response.data;
          const url = URL.createObjectURL(blob);
          console.log(url)
          setPdfUrl(url);
        } else {
          // Handle the error response
          console.log("error")
        }
      } catch (error) {
        // Handle the fetch error
      }
    };

    fetchPdfUrl();
  }, [filename, companyname]);


  const handleClose = () => {
    console.log("inside close", templateNames, companyNames);
    if (flow === "ckeditor") {
      const temp = templateNames
      const htmlContent = temp.replace(/\.pdf$/, '.html');

      navigate(toeditor, {
        state: { data: htmlContent, company: companyNames, flow: "update" },
      });
    } else {
      navigate(totemplatelist);
    }

  };







  return (
    <div className='editor-modal' >
      < form className="editor-modal-content-new animate">

        <div className="paper flex-grow-3">

          {pdfUrl ? (
            <div width="100%" height="500px">
              <embed src={pdfUrl} title="PDF Preview" width="100%" height="600px" />

            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div class="btn-container-multiple">
          <button
            class="modal-close-btn" onClick={handleClose}><b> {flow === "ckeditor" ? "Close" : "Close"}</b></button>
        </div>
      </form>

    </div>
  );
};

export default PdfPreview;
