import React from "react";
import { useState } from "react";
//import axios from 'axios';
import { useNavigate, useLocation } from "react-router";
//import EditorDataContext from '../Context/editorDataProvider';
import Select from "react-select";
import company from '../dataSource.json';
import ENV from "../../config";
import LeftNav from "../../Components/LeftNav/LeftNav";
import Header from "../../Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import '../../Css/pages.css'
import { Button } from "@mui/material";

const TemplateChooser = () => {
  //const [template, setTemplate] = useState('TEST');


  const companyval = [
    { value: company.company.CompanyId, label: company.company.CompanyName }

  ];
  const demo={ value: company.company.CompanyId, label: "Demo Company" }

  const [selectedOption, setSelectedOption] = useState(companyval[0]); // Set default option as the first option in the options array
  const [error, setError] = useState("");

  const [text, setText] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/cert-editorNew";
  const goback = location.state?.from?.pathname || "/"

  const handleSubmit = async (e) => {
    try {
      console.log(text);
      navigate(from, { state: { data: text, company: selectedOption.label, flow: "create" } });
    } catch (e) {
      console.log(" errored");
      setError("errord");
      console.log(e);
      console.log(text);
      navigate(from, { replace: true });
    }
  };

  function handleChange(event) {
    setText(event.target.value);
    //console.log(companyname)
  }
  function handleClose(event) {
    navigate('/list-templates');
  }

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "80%" }}>
            <main>
              <div class="path">
                <h2 class="active">
                  Create Template
                </h2>
                <h2>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                  >
                    <path
                      d="M1 11L4 6L1 1"
                      stroke="#8B8B8B"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </h2>
                <h2 class="inactive" >
                  Editor
                </h2>

              </div>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper">

                    <h1 style={{ margin: 0, alignContent: 'flex-start', display: 'flex' }}>Create Template</h1>
                    <label htmlFor="roles"><b>Company:</b></label>
                    <div>
                      <Select
                        options={companyval}
                        defaultValue={demo}
                        onChange={setSelectedOption}
                        isDisabled={true}
                      />
                    </div>

                    <label>
                      <b>Template Name:</b>
                      <input type="text" value={text} onChange={handleChange} />
                    </label>
                    <div style={{ position: "absolute", bottom: "5%", right: "2%" }}>
                      <div class="btn-container-multiple" style={{gap:"5%"}} >
                        <button class="modal-close-btn" onClick={handleClose}>
                          <b>Close</b>
                        </button>
                        <button class="modal-submit-btn" onClick={handleSubmit}>
                          <b>Proceed</b>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>







  );
};

export default TemplateChooser;
