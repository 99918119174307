import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import ENV from "../config";
import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import handleToken from '../Components/TokenHandler';

const FinalizedPdfPreview = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [comment, setComment] = useState('Rejected');
  const { auth } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const certeditorsurveyor = location.state?.from?.pathname || "/cert-editor-surveyor";
  const companyname = location.state.company;
  const surveyid = location.state.surveyid;
  const surveycertificateid = location.state.surveycertificateid;
  const surveycertificatename = location.state.surveycertificatename;
  const surveyname = location.state.surveyname;
  const flow = location.state.flow;
  console.log("dhbj", flow);
  const status = location.state.status;
  console.log("jhdjhcd", status);
  const totemplatelist = location.state?.from?.pathname || "/surveyor-dashboard"

  const [templateNames, setTemplateName] = useState(null)
  const APPROVE_SERVICE_URL = ENV.backend + '/api/surveyor/approve-certificate';
  const REJECT_SERVICE_URL = ENV.backend + '/api/surveyor/reject-certificate';

  useEffect(() => {
    handleToken();
  })

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        console.log(surveycertificatename, companyname, surveyid, surveyname, flow);
        const response = await axios.get(ENV.backend + '/api/surveyor/finalizedpdfpreview', {
          params: {
            certificateName: surveycertificatename,
            companyName: companyname,
            surveyId: surveyid,
            surveyName: surveyname,
            flow: flow
          },
          responseType: 'blob',
        }

        );

        if (response.status === 200) {
          console.log("status ok")
          console.log(response)
          const blob = response.data;
          const url = URL.createObjectURL(blob);
          console.log(url)
          setPdfUrl(url);
        } else {
          // Handle the error response
          console.log("error")
        }
      } catch (error) {
        // Handle the fetch error
      }
    };

    fetchPdfUrl();
  }, [surveycertificatename, companyname, surveyid, surveyname, flow]);

  const handleApprove = () => {
    let actionSubmittedBy = "";
    if (localStorage.getItem("userFullName") !== "undefined") {
      actionSubmittedBy = localStorage.getItem("userFullName");
    } else if (localStorage.getItem("userId")) {
      actionSubmittedBy = localStorage.getItem("userId");
    } else {
      actionSubmittedBy = "Unknown";
    }
    const data = {
      companyName: companyname,
      templateName: surveycertificatename,
      surveyCertificateName: surveycertificatename.replace(".pdf", ""),
      surveyCertificateId: surveycertificateid,
      surveyName: surveyname,
      surveyId: surveyid,
      updatedBy: localStorage.getItem("userId"),
      updatedOn: new Date(),
      actionSubmittedBy: actionSubmittedBy,
      actionSubmittedOn: new Date()
    };
    console.log(data)
    const response = axios.post(APPROVE_SERVICE_URL, data, {
      headers: {
        //	'Content-Type': 'text/html',
      }

    })


  };

  const handleReject = () => {
    let actionSubmittedBy = "";
    if (localStorage.getItem("userFullName") !== "undefined") {
      actionSubmittedBy = localStorage.getItem("userFullName");
    } else if (localStorage.getItem("userId")) {
      actionSubmittedBy = localStorage.getItem("userId");
    } else {
      actionSubmittedBy = "Unknown";
    }
    const data = {
      companyName: companyname,
      templateName: surveycertificatename,
      surveyCertificateName: surveycertificatename.replace(".pdf", ""),
      surveyCertificateId: surveycertificateid,
      surveyName: surveyname,
      surveyId: surveyid,
      comment: comment,
      updatedBy: localStorage.getItem("userId"),
      updatedOn: new Date(),
      actionSubmittedBy: actionSubmittedBy,
      actionSubmittedOn: new Date()
    };
    console.log(data)
    const response = axios.post(REJECT_SERVICE_URL, data, {
      headers: {
        //	'Content-Type': 'text/html',
      }

    })


  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
    console.log(comment)
  };


  const handleClose = () => {
    navigate(totemplatelist);   //


  };





  return (
    <div className='editor-modal' >
      < form className="editor-modal-content-new animate">

        <div className="paper flex-grow-3">

          {pdfUrl ? (
            <div width="100%" height="500px">
              <embed src={pdfUrl} title="PDF Preview" width="100%" height="600px" />

            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div class="btn-container-multiple">
          <button className="modal-close-btn" onClick={handleClose}><b>Close</b></button>
          {flow === "Approval" && (
            <button className="modal-reject-btn-new" onClick={handleReject}>Reject</button>
          )}
          {flow === "Approval" && (
            <button className="modal-submit-btn" onClick={handleApprove}>Approve</button>
          )}
          {flow === "Approval" && (
            <div>
              <label htmlFor="comment">Comment:</label>
              <textarea
                id="comment"
                value={comment}
                onChange={handleCommentChange}
                rows={4}
                cols={50}
              ></textarea>
            </div>
          )}
        </div>
      </form>

    </div>
  );
};

export default FinalizedPdfPreview;
