import React from 'react'
import {Link} from 'react-router-dom'
import { useNavigate, useLocation } from "react-router";
const Unauthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const goback = "/";
const handleClose = () => {
  navigate(goback);
};

const headingStyle = {
  color: 'red',
  fontSize: '24px',
  fontWeight: 'bold',
  // Add more CSS properties as needed
};
      
  return (
    <div id='id01' className="modal">
    <div  className="modal-content animate" >
    
  <h1>SignIn</h1>
  <Link to="/">Go to link pages</Link>
    <div  className='App'>
      <h2 style={headingStyle}>Sorry you do not have access to this page</h2>
     
    </div><button className="modal-close-btn" onClick={handleClose}>Close</button></div>
    </div>
  )
}

export default Unauthorized