

import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom'
import './Css/pages.css';
import axios from 'axios';
import env from "./config";
import LeftNav from './Components/LeftNav/LeftNav';
import Header from './Header/Header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import handleToken from './Components/TokenHandler';


const Register = () => {



    const navigate = useNavigate();
    const location = useLocation();
    const goback = location.state?.from?.pathname || "/";
    const from = location.state?.from?.pathname || "/signin"

    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [validName, setValidName] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [password, setPassword] = useState('');
    const [validPass, setValidPass] = useState(false);
    const [matchPassword, setMatchPassword] = useState('');
    const [checkMatch, setCheckMatch] = useState(false)
    const [email, setEmail] = useState('');
    const [roles, setRoles] = useState('');
    const [surveycompany, setSurveyCompany] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        handleToken();
    })
    /*let match;
    
       if(roles === 'HR') {
       match = '/register-hr'
       }else if (roles === 'SE'){
        match ='/register-se'
       }
       else if(roles === 'Marketers') {
        match ='/register-marketer'
       }
       const REGISTER_URL = match;*/
    useEffect(() => {
        // eslint-disable-next-line
        const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
        setValidName(USER_REGEX.test(lname));
    }, [lname]);

    useEffect(() => {
        // eslint-disable-next-line
        const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
        const result = PWD_REGEX.test(password);
        console.log(password);
        console.log(result);
        setValidPass(result);
        const match = (password === matchPassword)
        setCheckMatch(match);
        console.log(matchPassword);
    }, [password, matchPassword]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        const payload = {
            FirstName: fname,
            LastName: lname,
            password: password,
            Company: surveycompany,
            Username: email,
            ConfirmPassword: matchPassword,
            role: roles
        };

        if (validName && validPass && matchPassword) {
            try {
                const response = await axios.post(env.identityApi + '/api/identity/register', payload);

                // Do not navigate to the sign-in page after successful registration
                // navigate(from, { replace: true });
                console.log('Response:', response.data);
                setLName('');
                setFName('');
                setEmail('');
                setMatchPassword('');
                setPassword('');

                // Add the user to a role
                await axios.post(env.identityApi + '/api/identity/addusertorole', {
                    username: email,
                    role: roles
                });

                // Assuming you want to close the modal after successful registration
                // handleClose();
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 7000);

            } catch (error) {
                setError(error.message);
                console.error('There was an error!', error);
            }
        }
    };

    const handleClose = () => {
        navigate(goback);
    };




    return (
        <div>
            <header>
                <Header />
            </header>
            <div className="container-fluid">
                <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
                    <TabList className="hidden-tab-list" >
                        <Tab className="hidden-tab-list"></Tab>
                    </TabList>
                    <LeftNav />
                    <TabPanel style={{ width: "80%" }}>
                        <main>
                            <div className="container-fluid-buyer">
                                <div class="card-listing-section">
                                    {/* <h2 class="active-menu ml-3">Listings</h2> */}
                                    <div class="right-wrapper">
                                        <div >
                                            <h1 style={{ fontSize: "3rem" }} class="titlereg">Register</h1>
                                            <div>
                                                <form class="formregister">

                                                    {/* <p class="messagereg">Signup now and get full access to our app. </p> */}
                                                    <div class="flexreg">
                                                        <label style={{ width: "50%" }}>

                                                            <input id='fname'
                                                                placeholder="First Name" type="text" class="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => { setFName(e.target.value) }}
                                                                value={fname}
                                                                required
                                                            />


                                                            {/* <span>Firstname</span> */}

                                                        </label>
                                                        <br></br>
                                                        <label style={{ width: "50%" }}>
                                                            <input id='lname'
                                                                placeholder="Last Name" type="text" class="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => { setLName(e.target.value) }}
                                                                value={lname}
                                                                required
                                                            />
                                                            {/* <span>Lastname</span> */}
                                                        </label>
                                                    </div>

                                                    <label>

                                                        <input id='email'
                                                            placeholder="Email" type="email" class="inputreg"
                                                            autoComplete='off'
                                                            onChange={(e) => { setEmail(e.target.value) }}
                                                            value={email}
                                                            required
                                                        />
                                                        {/* <span>Email</span> */}
                                                    </label>
                                                    <div class="flexreg">

                                                        <FormControl fullWidth>
                                                            {/* <label htmlFor='surveycompany'>SurveyCompany</label> */}
                                                            <InputLabel htmlFor='surveycompany' id="select-label">Survey Company</InputLabel>

                                                            <Select sx={{ borderRadius: "10px" }}
                                                                labelId="select-label"
                                                                id="surveycompany"

                                                                label="SurveyCompany"
                                                                onChange={(e) => setSurveyCompany(e.target.value)}
                                                            >


                                                                <MenuItem value='' disabled selected>Select</MenuItem >
                                                                <MenuItem value='HEADOFFICE'>HEADOFFICE</MenuItem >
                                                                <MenuItem value='KOCHI'>KOCHI</MenuItem >
                                                                <MenuItem value='DUBAI'>DUBAI</MenuItem >
                                                            </Select>

                                                        </FormControl>


                                                        <FormControl fullWidth>
                                                            <InputLabel htmlFor="roles" id="select-labl">User Role</InputLabel>


                                                            <Select sx={{ borderRadius: "10px" }} id='roles' labelId="select-labl" label="userroles"
                                                                onChange={(e) => setRoles(e.target.value)}>
                                                                {console.log(roles)}
                                                                <MenuItem value='' disabled selected>Select a Role</MenuItem>
                                                                <MenuItem value='ADMIN'>Administrator</MenuItem>
                                                                <MenuItem value='USER'>Template Creator</MenuItem>
                                                                <MenuItem value='SURVEYOR'>Surveyor</MenuItem>
                                                                <MenuItem value='PROJECTMANAGER'>Project Manager</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                    </div>
                                                    <div class="flexreg">
                                                        <label style={{ width: "50%" }}>
                                                            <input id='password'
                                                                placeholder="Password" type="password" class="inputreg"
                                                                autoComplete='off'
                                                                onChange={(e) => { setPassword(e.target.value) }}
                                                                value={password}
                                                                required
                                                            />
                                                            {/* <span>Password</span> */}
                                                        </label><br></br>
                                                        <label style={{ width: "50%" }}>
                                                            <input id='matchpassword'
                                                                placeholder="Confirm Password" type="password" class="inputreg"
                                                                autoComplete="off"
                                                                onChange={(e) => setMatchPassword(e.target.value)}
                                                                value={matchPassword}
                                                                required
                                                            />
                                                            {/* <span>Confirm password</span> */}
                                                        </label>
                                                    </div>
                                                    {/* <button class="submitreg">Save</button>
    <p class="signin">Already have an acount ? <a href="#">Signin</a> </p> */}
                                                </form>
                                            </div>
                                        </div>
                                        {/* <button type="submit" disabled={!email || !validName || !validPass || !checkMatch ? true : false}>Submit</button>  */}
                                        {showMessage && <div><b><green>New profile created successfully!!!</green></b></div>}
                                        <div class="btn-container-multiple">
                                            <button style={{ marginRight: "23px" }} class="modal-submit-btn" type="submit" disabled={!email || !validName || !validPass || !checkMatch ? true : false} onClick={handleSubmit} ><b>Save</b></button>
                                        </div>
                                        {/* <button className="modal-close-btn" onClick={handleClose}><b>Close</b></button> */}

                                        {/* <span>
                                        <Link to="/Signin">Sign In</Link>
                                    </span> */}
                                    </div>
                                </div>

                            </div>
                        </main>
                    </TabPanel>
                </Tabs>
            </div>
        </div>


    )
}

export default Register
