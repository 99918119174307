import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import SurveyComponent from "../../Components/SurveyComponent";
import ENV from "../../config";
import Loader from "../../Components/Loader";
import Button from '@mui/material/Button';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import Header from "../../Header/Header";
import LeftNav from "../../Components/LeftNav/LeftNav";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleToken from "../../Components/TokenHandler";
import LoaderDashboard from "../../Components/LoaderDashboard";

function SurveyorDashboard() {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMoreStates, setViewMoreStates] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const goback = location.state?.from?.pathname || "/";

  useEffect(() => {
    fetchSurveys();
    handleToken();
  }, []);

  const handleClose = () => {
    navigate(goback);
  };

  const toggleViewMore = (key) => {
    setViewMoreStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const fetchSurveys = async () => {
    try {
      const response = await axios.get(ENV.backend + "/api/survey");

      console.log(Object.values(response.data));

      setSurveys(Object.values(response.data));
      // setSurveys(items)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setLoading(false);
      alert("error fetching surveys")
    }
  };



  const surveyMap = new Map();

  surveys.forEach((survey) => {
    const { companyName, ...rest } = survey;
    if (surveyMap.has(companyName)) {
      surveyMap.get(companyName).push(rest);
    } else {
      surveyMap.set(companyName, [rest]);
    }
  });

  const closeDashboard = () => {
    navigate(goback);
  };

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "80%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper">
                    <div class="scroll-Dash">

                      <div className="header-container">
                        <h1><div className="header-surveyor">Surveyor Dashboard </div></h1>
                        {/* 
                        <HighlightOffIcon sx={{
                          display: "flex",
                          color: "#e05050", fontSize: "50px"
                        }} onClick={closeDashboard}>

                        </HighlightOffIcon> */}
                      </div>
                      {
                        loading?( <LoaderDashboard />):(
                          <div>

                      {Array.from(surveyMap)
                        .sort(([, surveysA], [, surveysB]) => {
                          const latestSurveyA = surveysA.reduce((maxSurvey, survey) => (
                            maxSurvey.createdOn > survey.createdOn ? maxSurvey : survey
                          ), { createdOn: "" });

                          const latestSurveyB = surveysB.reduce((maxSurvey, survey) => (
                            maxSurvey.createdOn > survey.createdOn ? maxSurvey : survey
                          ), { createdOn: "" });

                          return (latestSurveyB.createdOn && latestSurveyA.createdOn)
                            ? latestSurveyB.createdOn.localeCompare(latestSurveyA.createdOn)
                            : 0;
                        })
                        .map(([key, value], index) => {

                          const newItems = value
                            .sort((a, b) => (b.createdOn && a.createdOn) ? b.createdOn.localeCompare(a.createdOn, undefined, { numeric: true, sensitivity: 'base' }) : 0)
                            .filter((survey) =>
                              survey.surveyCertificateList
                                .sort((a, b) => (b.createdOn && a.createdOn) ? b.createdOn.localeCompare(a.createdOn, undefined, { numeric: true, sensitivity: 'base' }) : 0)
                                .some((item) => item.surveyCertificateStatus === "New")
                            );



                          const inProgressItems = value.filter((survey) =>
                            survey.surveyCertificateList
                              .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)
                              .some(
                                (item) => item.surveyCertificateStatus === "In-Progress"
                              )
                          );

                          const completedItems = value.filter((survey) =>
                            survey.surveyCertificateList
                              .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)

                              .some(
                                (item) => item.surveyCertificateStatus === "Completed"
                              )
                          );
                          const approvedItems = value.filter((survey) =>
                            survey.surveyCertificateList
                              .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)
                              .some(
                                (item) => item.surveyCertificateStatus === "Approved"
                              )
                          );

                          return (
                            <div key={key}>
                              <Accordion defaultExpanded={index === 0}
                                sx={{
                                  boxShadow: "none",
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                  key={key}
                                  sx={{
                                    border: "0.1px solid",
                                    borderRadius: "10px",
                                    borderColor: "#bfcddb",
                                    marginBottom: '0.5rem',
                                    // marginTop: '0.5rem',
                                    backgroundColor: "#f4faff"

                                  }}
                                >
                                  <h2 style={{ marginBottom: "0" }}>{key}</h2>
                                </AccordionSummary>
                                <AccordionDetails key={key}
                                  sx={{
                                    borderRadius: "10px",
                                    // backgroundColor: "#f3eeee",
                                    margin: "0 0.5rem",
                                    padding: "0 ",
                                    marginBottom: "1rem",
                                    backgroundColor: "#eff5f9",

                                  }}
                                >
                                  <>
                                    {newItems.length !== 0 && (<div className="surveyornewitem">
                                    <label style={{ fontWeight: "bold", color: "brickred" }}>
                                        {" "}
                                         New Surveys
                                      </label>
                                      <SurveyComponent
                                        surveyItems={newItems}
                                        companyName={key}
                                        status={"New"}
                                      />
                                     
                                    </div>)}
                                    {inProgressItems.length !== 0 && (
                                      <div className="inprogress">
                                         <label style={{ fontWeight: "bold", color: "brickred" }}>
                                          {" "}
                                           In Progress Surveys
                                        </label>
                                        <SurveyComponent
                                          surveyItems={inProgressItems}
                                          companyName={key}
                                          status={"In-Progress"}
                                        />
                                       
                                      </div>
                                    )}
                                    {completedItems.length !== 0 && (
                                      <div className="completed">
                                         <label style={{ fontWeight: "bold", color: "brickred" }}>
                                          {" "}
                                          Completed Surveys
                                        </label>
                                        <SurveyComponent
                                          surveyItems={completedItems}
                                          companyName={key}
                                          status={"Completed"}
                                          flow={"SurveyorCompleted"}
                                        />
                                       
                                      </div>)}
                                    {approvedItems.length !== 0 && (
                                      <div className="approved">
                                        <label style={{ fontWeight: "bold", color: "brickred" }}>
                                          {" "}
                                           Approved Surveys
                                        </label>
                                        <SurveyComponent
                                          surveyItems={approvedItems}
                                          companyName={key}
                                          status={"Approved"}
                                          flow="SurveyorCompleted"
                                        />
                                        
                                      </div>
                                    )}
                                  </>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          );
                        })}
                        </div>
                        )}
                    </div>

                    {/* <button className="modal-close-btn" onClick={handleClose}>
                      Close
                    </button> */}
                  </div>

                </div>

              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>

  );
}

export default SurveyorDashboard;
