import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import Select from "react-select";
import ENV from "../config";
import getVessels from "./Vessels";
import getCountry from "./Country";
import getCompany from "./Company";
import Button from '@mui/material/Button';
import LeftNav from "../Components/LeftNav/LeftNav";
import Header from "../Header/Header";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddBoxIcon from '@mui/icons-material/AddBox';
import handleToken from "../Components/TokenHandler";

const AddCompanyDetails = () => {
  //const [company, setCompany] = useState('TEST');

  const [companies, setCompanies] = useState([]);

  const [vessel, setVessel] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState("");

  const [country, setCountry] = useState([]);
  const [selectedCoutry, setSelectedCountry] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [showAddVessel, setShowAddVessel] = useState(false);
  const [newCompany, setNewCompany] = useState("");
  const [newVessel, setNewVessel] = useState([]);
  const [imoNumber, setImoNumber] = useState([]);
  const [classId, setClassId] = useState([]);

  const [selectOption, setSelectOption] = useState([]); // Set default option as the first option in the options array
  const [error, setError] = useState("");



  const navigate = useNavigate();
  const location = useLocation();

  const errorpage = location.state?.from?.pathname || "/error";
  const goback = location.state?.from?.pathname || "/";

  useEffect(() => {
    fetchCompanies();
    handleToken();
  }, []);

  const fetchCompanies = async () => {
    try {
      const response = await getCompany("");
      console.log(response);
      setCompanies(response);

      //const companyval = [
      //{ value: companies.company.CompanyId, label: companies.company.CompanyName }

      //];
      setIsLoading(false);
    } catch (error) {
      // Handle error
      console.log("Error:", error);
      setIsLoading(false);
    }
  };

  const handleVesselSelect = (selectedOption) => {
    setSelectedVessel(selectedOption);
  };

  const options = companies
    .filter((company) => company.companyName !== null)
    .map((company) => ({
      value: company,
      label: company.companyName,
    }));

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Do something with the submitted data, such as sending it to the server

    //const companyId = new Number (selectOption);
    const vesselId = new Number(selectedVessel);
    const countryId = new Number(selectedCoutry);

    //console.log(companyId);
    console.log(vesselId);

    const newCompany = {

      companyId: selectOption.value,
      companyName: "DAKAR",

      vesselId: vesselId,
      countryId: countryId,

      createdBy: "Liz Mat",
      updatedBy: "Liz Mat",
      createdOn: new Date(),
      updatedOn: new Date(),
    };

    console.log(newCompany);

    // try {
    //   // Make an API call to the backend to create a new user
    //   const response = await axios.post(
    //     ENV.backend+"/api/company",
    //     newCompany
    //   );

    //   if (response.status === 201) {
    //     // User created successfully
    //     console.log("Company created!");
    //     // Additional actions if needed
    //   } else {
    //     // Error occurred while creating the user
    //     console.error("Error creating Company");
    //     // Additional error handling if needed
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    //   // Additional error handling if needed
    // }

    // Reset input fields and selections
  };

  const handleCompanySelect = async (selectedOption) => {
    console.log(selectedOption);
    // setSelectOption(value);

    setSelectedCompany(selectedOption);
    setSelectedVessel(null);

    try {
      const response = await getVessels("", selectedOption.value);
      console.log(response);

      const v = response.filter((v) => v.companyId === selectedOption.value.companyId)
        .map((v) => ({
          value: v,
          label: v.vesselName,
        }));
      setVessel(v)


    } catch (error) {
      // Handle error
      console.log("Error:", error);
    }
  };



  const handleAddCompany = () => {
    setShowAddCompany(true);
  };

  const closeAddCompanySubmit = () => {
    setShowAddCompany(false)
  }

  const handleAddCompanySubmit = async () => {


    const newCompanyObject = {
      companyName: newCompany,
      vesselList: [],
      countryList: [],
    };

    try {
      // Make an API call to the backend to create a new user
      const response = await axios.post(
        ENV.backend + "/api/company",
        newCompanyObject
      );

      if (response.status === 201) {
        // User created successfully
        console.log("Company created!", response);
        // Additional actions if needed
        setCompanies([...companies, response.data]);
      } else {
        // Error occurred while creating the user
        console.error("Error creating Company");
        // Additional error handling if needed
      }
    } catch (error) {
      console.error("Error:", error);
      // Additional error handling if needed
    }

    // Add the new company to the state
    // setCompanies([...companies, newCompanyObject]);
    setNewCompany("");
    setShowAddCompany(false);

  };

  const handleAddVessel = () => {
    setShowAddVessel(true);

  };

  const closeAddVesselSubmit = () => {
    setShowAddVessel(false);
  }

  const handleAddVesselSubmit = async (event) => {
    event.preventDefault();
    console.log(selectedCompany)

    const newVesselObject = {
      companyId: selectedCompany.value.companyId,
      vesselName: newVessel,
      imoNumber: imoNumber,
      classId: classId,
      createdOn: new Date(),
      createdBy: localStorage.getItem('userEmail'),
    };



    console.log(newVesselObject)

    try {
      // Make an API call to the backend to create a new user
      const response = await axios.post(
        ENV.backend + "/api/vessel",
        newVesselObject
      );

      if (response.status === 201) {
        // User created successfully
        console.log("respo", response);


        const formattedVessel = {
          value: response.data,
          label: newVesselObject.vesselName,
        };

        setVessel([...vessel, formattedVessel]);

        console.log("Vessel created!");
        // Additional actions if needed
      } else {
        // Error occurred while creating the user
        console.error("Error creating Vessel");
        // Additional error handling if needed
      }
    } catch (error) {
      console.error("Error:", error);
      // Additional error handling if needed
    }

    // Add the new company to the state
    setNewVessel("");
    setClassId("");
    setImoNumber("");
    setShowAddVessel(false);
  };

  function handleClose(event) {
    navigate(goback);
  }

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "80%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper">
                    <h1 style={{ alignItems: "flex-start", display: "flex" }}>Client Details</h1>
                    <label className="label" htmlFor="roles">Client</label>
                    <div className="select-container">
                      <div className="custom-select">
                        <Select
                          options={options}
                          defaultValue={selectedCompany}
                          onChange={handleCompanySelect} //{setSelectedOption}
                          required
                        />
                      </div>
                      {!showAddCompany && (
                        <div className="button-container">
                          <AddBoxIcon sx={{ color: "green", width: '40px', height: '40px', marginTop: "-1px", marginRight: "-5px" }} onClick={handleAddCompany}></AddBoxIcon>
                        </div>
                      )}
                    </div>


                    {showAddCompany && (
                      <div>
                        <div id="id02" className="submodal">
                          <div className="modal-content animate">
                            <div className="submodal-container">
                              <h2>Add New Client</h2>
                              {/* <button className="cross-button" onClick={closeAddCompanySubmit}>X</button> */}

                              <input
                                type="text"
                                value={newCompany}
                                onChange={(e) => setNewCompany(e.target.value)}
                                placeholder="New Client"
                              />
                              <div class="btn-container-multiple">
                                <button className="modal-close-btn" onClick={closeAddCompanySubmit}><b>Close</b></button>
                                <button className="modal-submit-btn" onClick={handleAddCompanySubmit}><b>Save</b></button>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    )}
                    {selectedCompany && (

                      <div>
                        <br></br>
                        <label className="label">Vessel</label>
                        <div className="select-container">
                          <div className="custom-select">
                            <Select
                              options={vessel}
                              value={selectedVessel}
                              onChange={handleVesselSelect}
                              placeholder="Select a  vessel"
                            />
                          </div>
                          <div className="button-container">
                            <AddBoxIcon sx={{ color: "green", width: '40px', height: '40px', marginTop: "-1px", marginRight: "-5px" }} onClick={handleAddVessel}>+</AddBoxIcon>
                          </div>
                        </div>
                      </div>
                    )}

                    {showAddVessel && (
                      <div id="id02" className="submodal">
                        <div className="modal-content animate">
                          <div className="submodal-container">
                            <h2>Add New Vessel</h2>
                            {/* <button className="cross-button" onClick={closeAddVesselSubmit}>X</button> */}
                            <input
                              type="text"
                              value={newVessel}
                              onChange={(e) => setNewVessel(e.target.value)}
                              placeholder="New Vessel"
                            />
                            <input
                              type="text"
                              value={classId}
                              onChange={(e) => setClassId(e.target.value)}
                              placeholder="Add Class Id"
                            />
                            <input
                              type="text"
                              value={imoNumber}
                              onChange={(e) => setImoNumber(e.target.value)}
                              placeholder="Add IMO Number"
                            />
                            <div class="btn-container-multiple">
                              <button className="modal-close-btn" onClick={closeAddVesselSubmit}>Close</button>
                              <button className="modal-submit-btn" onClick={handleAddVesselSubmit}>Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}



                    <br></br>
                    {/* <Button style={{ color: '#ffc9c9', backgroundColor: '#8c111f', textTransform: 'none' }} onClick={handleClose}>
          <b>Close </b>
        </Button> */}
                  </div>
                </div>

              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>

  );
};

export default AddCompanyDetails;
