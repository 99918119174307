import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom'
import ENV from "../config";
import { useContext } from "react";
import AuthContext from "../Context/authProvider";
import handleToken from '../Components/TokenHandler';

const ApprovedFinalizedPdfPreview = () => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [comment, setComment] = useState('');
  const { auth } = useContext(AuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const companyname = location.state.company;
  const vesselid = location.state.vesselid;
  const surveyid = location.state.surveyid;
  const surveycertificateid = location.state.surveycertificateid;
  const surveycertificatename = location.state.surveycertificatename;
  const surveyname = location.state.surveyname;
  const surveycertificategoodname = location.state.surveycertificategoodname;
  const issuedon = location.state.issuedon;
  const expirydate = location.state.expirydate;
  const flow = location.state.flow;
  const role = localStorage.getItem('role')
  const status = location.state.status
  const [isCommentProvided, setIsCommentProvided] = useState(true);
  const totemplatelist = location.state?.from?.pathname || "/approver-dashboard"

  const APPROVE_SERVICE_URL = ENV.backend + '/api/surveyor/approve-certificate';
  const REJECT_SERVICE_URL = ENV.backend + '/api/surveyor/reject-certificate';
  const [rejectFlag, setRejectFlag] = useState(false);

  useEffect(() => {
    handleToken();
  })

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        console.log(surveycertificatename, companyname, vesselid, surveyid, surveyname, surveycertificategoodname, issuedon, expirydate, flow);
        const response = await axios.get(ENV.backend + '/api/surveyor/finalizedpdfpreview', {
          params: {
            certificateName: surveycertificatename,
            companyName: companyname,
            surveyId: surveyid,
            surveyName: surveyname,
            flow: flow
          },
          responseType: 'blob',
        }

        );

        if (response.status === 200) {
          console.log("status ok")
          console.log(response)
          const blob = response.data;
          const url = URL.createObjectURL(blob);
          console.log(url)
          setPdfUrl(url);
        } else {
          // Handle the error response
          console.log("error")
        }
      } catch (error) {
        // Handle the fetch error
      }
    };
    if (rejectFlag === false) {
      fetchPdfUrl();
      setRejectFlag(true);
    }

  }, [surveycertificatename, companyname, vesselid, surveyid, surveyname, surveycertificategoodname, issuedon, expirydate]);

  const handleApprove = () => {
    let actionSubmittedBy = "";
    if (localStorage.getItem("userFullName") !== "undefined") {
      actionSubmittedBy = localStorage.getItem("userFullName");
    } else if (localStorage.getItem("userId")) {
      actionSubmittedBy = localStorage.getItem("userId");
    } else {
      actionSubmittedBy = "Unknown";
    }
    const data = {
      companyName: companyname,
      vesselId: Number(vesselid),
      templateName: surveycertificatename,
      surveyCertificateName: surveycertificatename.replace(".pdf", ""),
      surveyCertificateId: surveycertificateid,
      surveyName: surveyname,
      surveyId: surveyid,
      actionSubmittedBy: actionSubmittedBy,
      actionSubmittedOn: new Date(),
      certificateGoodName: surveycertificategoodname,
      certIssueDate: issuedon,
      certExpiryDate: expirydate,
      updatedBy: localStorage.getItem("userId"),
      updatedOn: new Date()
    };
    console.log(data)
    const response = axios.post(APPROVE_SERVICE_URL, data, {
      headers: {
        //	'Content-Type': 'text/html',
      }

    })
      .then(() => {

        window.location.reload();
      })
      .catch(error => {
        console.error('Error approving:', error);
      });

    handleClose();
  };

  const handleReject = () => {
    if (comment === "") {
      setIsCommentProvided(false)
    }
    else {


      let actionSubmittedBy = "";
      if (localStorage.getItem("userFullName") !== "undefined") {
        actionSubmittedBy = localStorage.getItem("userFullName");
      } else if (localStorage.getItem("userId")) {
        actionSubmittedBy = localStorage.getItem("userId");
      } else {
        actionSubmittedBy = "Unknown";
      }
      const data = {
        companyName: companyname,
        templateName: surveycertificatename,
        surveyCertificateName: surveycertificatename.replace(".pdf", ""),
        surveyCertificateId: surveycertificateid,
        surveyName: surveyname,
        surveyId: surveyid,
        comment: comment,
        updatedBy: localStorage.getItem("userId"),
        updatedOn: new Date(),
        actionSubmittedBy: actionSubmittedBy,
        actionSubmittedOn: new Date()
      };
      console.log(data)
      const response = axios.post(REJECT_SERVICE_URL, data, {
        headers: {
          //	'Content-Type': 'text/html',
        }

      })
        .then(() => {

          window.location.reload();
        })
        .catch(error => {
          console.error('Error rejecting:', error);
        });


      handleClose();
    }
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
    setIsCommentProvided(event.target.value.trim() !== '');
    console.log(comment)
  };


  const handleClose = () => {

    navigate(totemplatelist);

  };




  return (
    <div className='editor-modal' >
      < form className="editor-modal-content-new animate">

        <div className="paper flex-grow-3">

          {pdfUrl ? (
            <div width="100%" height="500px">
              <embed src={pdfUrl} title="PDF Preview" width="100%" height="600px" />

            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>



        {((flow === "Approval" && role === "Admin") || (flow === "Approval" && role === "Project Manager" && status === "Completed")) && (


          <div>

            <label htmlFor="comment" style={{ paddingTop: "5px", display: "flex", alignItems: "center" }} >
              {!isCommentProvided && <span style={{ color: 'red', fontSize: "26px" }}> *</span>}<b>Rejection Reason:</b>
            </label>
            <textarea
              id="comment"
              value={comment}
              placeholder='Provide a reason to reject.'
              onChange={handleCommentChange}
              rows={3}
              cols={50}
              style={{ width: "100%" }}
            ></textarea>
            {/* {!isCommentProvided && <h5 style={{ color: 'red', marginTop: "0" }}>Please provide a rejection reason to reject.</h5>} */}
          </div>
        )}
        <div class="btn-container-multiple">
          <button className="modal-close-btn" onClick={handleClose}><b>Close</b></button>
          {((flow === "Approval" && role === "Admin") || (flow === "Approval" && role === "Project Manager" && status === "Completed")) && (
            <button className="modal-reject-btn" onClick={handleReject} disabled={!isCommentProvided}><b>Reject</b></button>)}
          {((flow === "Approval" && status === "Completed") || (flow === "Approval" && status !== "Completed" && role === "Admin")) && (
            <button className="modal-submit-btn" onClick={handleApprove}><b>Approve</b></button>
          )}
        </div>
      </form>

    </div>
  );
};

export default ApprovedFinalizedPdfPreview;