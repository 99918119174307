import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../../Css/surveydashboard.css';
import { PieChart } from 'recharts';
import ENV from "../../config";
import Loader from '../../Components/Loader';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Header from '../../Header/Header';
import LeftNav from '../../Components/LeftNav/LeftNav';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import { Tooltip } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import handleToken from '../../Components/TokenHandler';
import LoaderDashboard from '../../Components/LoaderDashboard';

function SurveyDashboard() {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const surveyMap = new Map();

  surveys
    .sort((b, a) => (a.createdOn && b.createdOn) ? a.createdOn.localeCompare(b.createdOn) : 0)
    .forEach((survey) => {
      const { companyName, ...rest } = survey;
      if (surveyMap.has(companyName)) {
        surveyMap.get(companyName).push(rest);
      } else {
        surveyMap.set(companyName, [rest]);
      }
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(2);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.from(surveyMap.entries()).slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(surveyMap.size / itemsPerPage);

  const navigate = useNavigate();
  const location = useLocation();

  const goback = location.state?.from?.pathname || "/";
  const createsurvey = location.state?.from?.pathname || "/create-survey";


  const [expandedCertificates, setExpandedCertificates] = useState({});

  useEffect(() => {
    fetchSurveys();
    handleToken();
  }, []);

  const handleClose = () => {
    console.log("Go back from survey dashboard", goback);
    navigate(goback);
  };

  const handleCreate = () => {
    navigate(createsurvey);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const ListItem = ({ item }) => {
    return <li>{item}</li>;
  };

  const handleToggleCertificates = (surveyId) => {
    setExpandedCertificates((prevExpandedCertificates) => ({
      ...prevExpandedCertificates,
      [surveyId]: !prevExpandedCertificates[surveyId],
    }));
  };


  const fetchSurveys = async () => {
    try {
      const response = await axios.get(ENV.backend + '/api/survey');
      console.log(response)
      response.data.map(item => {
        if (item.companyName === 'Cstor Maritime') {
          console.log(item)
        }
      })
      setSurveys(Object.values(response.data));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching surveys:', error);
      setLoading(false);
      alert("error fetching surveys")
    }
  };

 





  const pieChartData = surveys.reduce((acc, survey) => {
    const { surveyStatus, count } = survey;
    acc[surveyStatus] = count;
    return acc;
  }, {});

  const pieChartOptions = {
    radius: 150,
    colors: ['#ff0000', '#00ff00', '#0000ff'],
  };

  return (
    <div>
      <header>
        <Header />
      </header>
      <div className="container-fluid">
        <Tabs className="vertical-tabs" style={{ height: 'fit-content' }}>
          <TabList className="hidden-tab-list" >
            <Tab className="hidden-tab-list"></Tab>
          </TabList>
          <LeftNav />
          <TabPanel style={{ width: "80%" }}>
            <main>
              <div className="container-fluid-buyer">
                <div class="card-listing-section">
                  {/* <h2 class="active-menu ml-3">Listings</h2> */}
                  <div class="right-wrapper">
                  
                    <div class="scroll-Dash">
                      <div className="header-container">
                        <div style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "space-between",
                          width: "-webkit-fill-available",
                          height: "60px"
                        }}>
                          <h1 style={{ margin: "0px" }}><div className="header-surveyor">Survey Dashboard</div></h1>
                          <Tooltip title="Create New Survey" arrow>
                            <AddToPhotosIcon sx={{ fontSize: "2rem", color: "green", cursor: "pointer" }} onClick={handleCreate} />
                          </Tooltip>
                        </div>
                        {/* <HighlightOffIcon sx={{
                          display: "flex",
                          color: "#e05050", fontSize: "50px", marginTop: "-26px", marginRight: "-26px"
                        }} onClick={handleClose}>

                        </HighlightOffIcon> */}
                      </div>
                      {
                        loading?( <LoaderDashboard />):(
                          <>
                          <div>
                        <PieChart data={pieChartData} options={pieChartOptions} />
                      </div>
                      <div className="row">
                        <div className="col">
                          {
                            // currentItems.
                            Array.from(surveyMap.entries()).map(([key, value], index, sectionIndex, array) => {
                              // const previousCompanyName = array[sectionIndex - 1]?.[0];
                              // const currentCompanyName = key;

                              // console.log(previousCompanyName)
                              // console.log(currentCompanyName)
                              // const showCardHeader = currentCompanyName !== previousCompanyName;

                              return (
                                <div key={key}>
                                  <Accordion
                                    defaultExpanded={index === 0}
                                    sx={{
                                      boxShadow: "none",
                                    }}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                      key={key}
                                      sx={{
                                        border: "0.1px solid",
                                        borderRadius: "10px",
                                        borderColor: "#bfcddb",
                                        marginBottom: '0.5rem',
                                        // marginTop: '0.5rem',
                                        backgroundColor: "#f4faff",
                                        // margin: "0.5rem"
                                      }}
                                    >
                                      <h2 style={{ marginBottom: "0" }}>{key}</h2>
                                    </AccordionSummary>
                                    <AccordionDetails key={key}
                                      sx={{
                                        borderRadius: "10px",
                                        // backgroundColor: "#f3eeee",
                                        margin: "0rem  0.5rem",
                                        marginBottom: "0.5rem",
                                        padding: "0 ",

                                      }}
                                    >
                                      <div className="table-responsive">
                                        <table className="table align-items-center table-flush " style={{ backgroundColor: "#d6e8f7" }}>
                                          <thead className="thead-light">
                                            <tr>
                                              <th scope="col" ><b>Survey Id</b></th>
                                              <th scope="col"><b>Survey Name</b></th>
                                              <th scope="col"><b>Status</b></th>
                                              <th scope="col"><b>Surveyors</b></th>
                                              <th scope="col"><b>Survey Items</b></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {value.map((survey) => (
                                              <tr key={survey.surveyId}>
                                                <div className="media align-items-center">
                                                  <div className="media-body">
                                                    <span className="mb-0 text-sm">{survey.surveyId}</span>
                                                  </div>
                                                </div>
                                                <td>{survey.surveyName}</td>
                                                <td>
                                                  <span className="badge badge-dot mr-4">
                                                    <i className="bg-warning"></i>
                                                    {survey.surveyStatus}
                                                  </span>
                                                </td>
                                                <td>
                                                  <ul><li>{(survey.surveyorsList || []).map((item, index) => (
                                                    <ListItem key={index} item={item.userId} />
                                                  ))}</li></ul>
                                                </td>
                                                <td>

                                                  {expandedCertificates[survey.surveyId] ? (
                                                    <>
                                                      <ul className="certificate-list">
                                                        {(survey.surveyCertificateList || []).map((item, index) => (
                                                          <ListItem key={index} item={item.certificateName} />
                                                        ))}
                                                      </ul>
                                                      <div className="certificate-overflow" onClick={() => handleToggleCertificates(survey.surveyId)}><b>Show Less</b></div>

                                                    </>
                                                  ) : (
                                                    <>
                                                      <ul className="certificate-list">
                                                        {(survey.surveyCertificateList || []).slice(0, 3).map((item, index) => (
                                                          <ListItem key={index} item={item.certificateName} />
                                                        ))}
                                                      </ul>
                                                      {(survey.surveyCertificateList || []).length > 3 && (
                                                        <div className="certificate-overflow" onClick={() => handleToggleCertificates(survey.surveyId)} >
                                                          <b>Show More</b>
                                                        </div>
                                                      )}
                                                    </>
                                                  )}

                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>

                              );
                            })}
                        </div>
                      </div>
                      </>
                        )
                          
                        
                      }
                      

                      {/* <div className="pagination">
                        {currentPage > 1 && (
                          <button className="pagination-prev" onClick={() => handlePageChange(currentPage - 1)}>
                            Previous
                          </button>
                        )}

                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                          <Link
                            key={page}
                            to={`?page=${page}`}
                            className={`pagination-btn ${currentPage === page ? 'active' : ''}`}
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </Link>
                        ))}

                        {currentPage < totalPages && (
                          <button className="pagination-next" onClick={() => handlePageChange(currentPage + 1)}>
                            Next
                          </button>
                        )}
                      </div> */}

                    </div>
                    {/* <div class="btn-container-multiple">

                      <button className="modal-submit-btn" onClick={handleCreate}>
                        <b>Create New Survey</b>
                      </button>
                    </div> */}
                    {/* <button className="modal-close-btn" onClick={handleClose}>
                      <b>Close</b>
                    </button> */}
                  </div>
                </div>

              </div>
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

export default SurveyDashboard;
