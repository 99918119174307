import './LeftNav.css'
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { React, useState, useEffect } from 'react';
import {
    FaBars,
    FaUserAlt,
    FaCommentAlt
} from "react-icons/fa";
import { FaRegCircleUser } from "react-icons/fa6";
import { LuUsers } from "react-icons/lu";
import { IoInformationCircleOutline } from "react-icons/io5";
import { IoDocumentsOutline } from "react-icons/io5";
import { FiShoppingBag } from "react-icons/fi";
import { MdApartment } from "react-icons/md";
import { BiCog, BiHelpCircle, BiBriefcase } from 'react-icons/bi';
import { TbMessages } from 'react-icons/tb';
import { NavLink, useLocation } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SummarizeIcon from '@mui/icons-material/Summarize';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AccountTreeIcon from '@mui/icons-material/AccountTree';


const LeftNav = ({ }) => {
    const location = useLocation();
    const isMenuItemActive = (path) => location.pathname.includes(path);
    const [expandedMenu, setExpandedMenu] = useState(null);

    const handleMenuClick = (index) => {
        setExpandedMenu(expandedMenu === index ? null : index);
    };



    const menuItem = [
        {
            path: "/register",
            name: "Register",
            icon: <HowToRegIcon />,
            roles: ["Admin"]
        },
        {
            name: "Templates",
            icon: <SummarizeIcon />,
            roles: ["Admin"],
            path: "/list-templates",
            // submenus: [
            //     {
            //         path: "/choose-template",
            //         name: "Create Template",
            //         icon: <NoteAddIcon />,
            //         roles: ["Admin"],

            //     },
            //     {
            //         path: "/list-templates",
            //         name: "List Templates Created",
            //         icon: <TextSnippetIcon />,
            //         roles: ["Admin"],
            //     },

            // ],
        },
        {
            name: "Dashboards",
            icon: <DashboardIcon />,
            roles: ["Admin", "Project Manager", "Surveyor"],
            submenus: [
                {
                    path: "/survey-dashboard",
                    name: "Survey Dashboard",
                    icon: <CreateNewFolderIcon />,
                    roles: ["Admin", "Project Manager"]

                },
                {
                    path: "/surveyor-dashboard",
                    name: "Surveyor Dashboard",
                    icon: <AccountTreeIcon />,
                    roles: ["Admin", "Project Manager", "Surveyor"],

                },
                {
                    path: "/approver-dashboard",
                    name: "Approver Dashboard",
                    icon: <AssignmentTurnedInIcon />,
                    roles: ["Admin", "Project Manager"],

                },
            ],
        },
        {
            path: "/add-company-details",
            name: "Client Details",
            icon: <AddBusinessIcon />,
            roles: ["Admin", "Project Manager"]
        },
    ]
    useEffect(() => {
        // Check if the current location matches any submenu path
        const matchedSubmenuIndex = menuItem.findIndex(
            (item) => item.submenus && item.submenus.some((submenu) => location.pathname.includes(submenu.path))
        );

        if (matchedSubmenuIndex !== -1) {
            setExpandedMenu(matchedSubmenuIndex);
        } else {
            setExpandedMenu(null);
        }
    }, [location.pathname]);

    const userRole = localStorage.getItem('role')
    const filteredMenuItems = menuItem.filter(item => {
        return item.roles.includes(userRole);
    });

    // Filter submenus based on user role
    const filteredSubmenus = filteredMenuItems.map(item => {
        if (item.submenus) {
            const filteredSubs = item.submenus.filter(submenu => {
                return submenu.roles.includes(userRole);
            });
            return { ...item, submenus: filteredSubs };
        }
        return item;
    });
    return (


        <TabList className="vertical-tab-list">
            <div className="container">
                {filteredSubmenus.map((item, index) => (
                    <div key={index} style={{ width: "100%" }}>
                        <NavLink to={item.path} className={`${(isMenuItemActive(item.path) && expandedMenu !== index) ? 'linkselectedmenu' : 'link'} `}
                            onClick={() => handleMenuClick(index)}
                        >
                            <div className="icon">{item.icon}</div>
                            <div className="link_text">{item.name}</div>
                        </NavLink>
                        {/* Render submenus if they exist */}
                        {item.submenus && expandedMenu === index && (
                            <div className="submenu-container">
                                {item.submenus.map((submenu, subIndex) => (
                                    <NavLink
                                        key={subIndex}
                                        to={submenu.path}
                                        className={`${isMenuItemActive(submenu.path) ? 'linkselectedmenu' : 'link'}`}
                                    // activeClassName="active-leftNav"
                                    >
                                        <div className="icon">{submenu.icon}</div>
                                        <div className="link_text">{submenu.name}</div>
                                    </NavLink>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </TabList>


    );
}

export default LeftNav;
