import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from '@mui/material';
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

const defaultMaterialTheme = createTheme();

function ApproveSurveyCertificateTable({ data, surveyName, surveyId, companyName, vesselId, flow, status }) {

  const navigate = useNavigate();
  const location = useLocation();

  const topdfpreviewer = location.state?.from?.pathname || "/approved-finalized-pdf-previewer";

  const filteredItems = data.filter(surveyCertificateItem => surveyCertificateItem.surveyCertificateStatus === status);

  const handlePDF = (surveyCertificateId, certificateName, certificateGoodName, issuedDate, expiryDate, flow) => {
    if (certificateName && certificateName !== null) {
      certificateName = certificateName.replace(".html", ".pdf");
    }

    console.log(certificateName);
    console.log(vesselId);

    try {
      navigate(topdfpreviewer, {
        state: {
          company: companyName,
          vesselid: vesselId,
          surveyid: surveyId,
          surveyname: surveyName,
          surveycertificateid: surveyCertificateId,
          surveycertificatename: certificateName,
          surveycertificategoodname: certificateGoodName,
          issuedon: issuedDate,
          expirydate: expiryDate,
          flow: flow,
          status: status
        },
      });
    } catch (e) {
      console.log("errored");
      console.log(e);
    }
  };


  const handleButtonClick = (rowData) => {
    console.log("Button clicked with editor data:", rowData.certificateName);
    console.log(flow)


    handlePDF(rowData.surveyCertificateId, rowData.certificateName, rowData.certificateGoodName, rowData.issuedDate, rowData.expiryDate, flow);

  };

  const columns = [
    { title: "Certificate Name", field: "certificateName" },
    { title: "Current Surveyors", field: "updatedBy" },
    { title: "Certificate Status", field: "surveyCertificateStatus" }
  ];

  const actions = [
    {
      icon: "picture_as_pdf",
      tooltip: "PDF Preview",
      onClick: (event, rowData) => handleButtonClick(rowData),
    },
  ];

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <ThemeProvider theme={defaultMaterialTheme}>
        <MaterialTable
          title="Survey Certificate Table"
          actions={actions}
          columns={columns}
          data={filteredItems}
          options={{
            paging: false,
            maxBodyHeight: "45vh"
          }}
          direction="rtl"
        />
      </ThemeProvider>
    </div>
  );
}

export default ApproveSurveyCertificateTable;
